import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { Week4Component } from './week4.component';
import { Week4Page1Component } from './week4-page1/week4-page1.component';
import { Week4Page2Component } from './week4-page2/week4-page2.component';
import { Week4Page3Component } from './week4-page3/week4-page3.component';
import { Week4Page4Component } from './week4-page4/week4-page4.component';
import { Week4Page5Component } from './week4-page5/week4-page5.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AngularEditorModule,
    RouterModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    Week4Component,
    Week4Page1Component,
    Week4Page2Component,
    Week4Page3Component,
    Week4Page4Component,
    Week4Page5Component,
  ],
  exports: [Week4Page2Component],
})
export class Week4Module {}
