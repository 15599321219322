<div class="container p-4 container-custom outer">
  <h1
    style="
      color: #012233;
      margin-bottom: 10px !important;
      font-size: 35px !important;
    "
  >
    <b>{{ "INTRO_P3_TIME_LINE" | translate }}</b>
  </h1>
  <br />
  <h4 style="color: #002e46; font-size: 18px; align-content: left">
    <p>
      {{ "INTRO_P3_TIME_LINE_DETAILS" | translate }}
    </p>
  </h4>
  <br />
  <br />
  <div class="timeline-container">
    <!-- <div class="week-section">
      <div class="week-content">
        <h3>
          <b>{{ "W1" | translate }}</b>
        </h3>
        <p>
          {{ "INTRO_P3_WEEK1_DETAILS" | translate }}
        </p>
      </div>
    </div>

    <div class="week-section">
      <div class="week-content">
        <h3>
          <b>{{ "W2" | translate }}</b>
        </h3>
        <p>
          {{ "INTRO_P3_WEEK2_DETAILS" | translate }}
        </p>
      </div>
    </div>

    <div class="week-section">
      <div class="week-content">
        <h3>
          <b>{{ "W3" | translate }}</b>
        </h3>
        <p>
          {{ "INTRO_P3_WEEK3_DETAILS" | translate }}
        </p>
      </div>
    </div>

    <div class="week-section">
      <div class="week-content">
        <h3>
          <b>{{ "W4" | translate }}</b>
        </h3>
        <p>
          {{ "INTRO_P3_WEEK4_DETAILS" | translate }}
        </p>
      </div>
    </div>
    <div class="week-section">
      <div class="week-content">
        <h3>
          <b>{{ "W5" | translate }}</b>
        </h3>
        <p>
          {{ "INTRO_P3_WEEK5_DETAILS" | translate }}
        </p>
      </div>
    </div> -->

    <h2
      style="
        color: #012233;
        margin-bottom: 10px !important;
        font-size: 35px !important;
      "
    >
      {{ "INTRO_P3_HOW_KNOW" | translate }}
    </h2>
    <h4 style="color: #002e46; font-size: 18px; align-content: left">
      <p>
        {{ "INTRO_P3_HOW_KNOW_ANSWER" | translate }}
      </p>
    </h4>
    <br />
    <div class="form-group text-center">
      <button
        class="btn btn-primary field mx-auto col-3"
        style="min-width: 140px"
        (click)="onNextClick()"
      >
        <div *ngIf="chkFlg === '1'">
          {{ "INTRO_P3_BUTTON_START" | translate }}
        </div>
        <div *ngIf="chkFlg === '2'">
          {{ "NEXT" | translate }}
        </div>
      </button>
    </div>
  </div>
</div>
