import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { AdministratorService } from '@app/_services/administrator.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'administrator',
  templateUrl: './administrator.component.html',
  styleUrls: ['./administrator.component.less'],
})
export class AdministratorComponent implements OnInit {
  adminList: Array<Object>;

  constructor(
    public administratorService: AdministratorService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.administratorService.resetPaginationDetails();
    this.administratorService
      .getAdministratorDetails()
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.adminList = data.results;
        },
        error: (error) => {
          this.alertService.error(error);
          // this.loading = false;
        },
      });
  }

  navigateToAdminPage(): void {
    this.router.navigate(['/add-admin']);
  }

  moveToNextPage(event: any) {
    event.preventDefault();
    this.administratorService.moveToNextPage().subscribe({
      next: (data) => {
        this.adminList = data.results;
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }

  moveToPreviousPage(event: any) {
    event.preventDefault();
    this.administratorService.moveToPreviousPage().subscribe({
      next: (data) => {
        this.adminList = data.results;
      },
      error: (error) => {
        this.alertService.error(error);
      },
    });
  }
}
