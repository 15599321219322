<div class="desktop">
  <div class="custom-dialog">
    <h2>{{ "DIALOG_COMP_TITLE" | translate }}!</h2>
    <h3>
      <p>{{ "DIALOG_COMP_CONTENT1" | translate }}</p>
      <p>
        {{ "DIALOG_COMP_CONTENT2" | translate }}
      </p>
    </h3>
    <div class="form-group text-center">
      <button
        class="btn btn-primary field mx-auto col-3 me-2"
        (click)="logJournal()"
        style="min-width: 145px"
      >
        {{ "DIALOG_LOG_JOURNAL" | translate }}
      </button>
      <button
        class="btn btn-primary field mx-auto col-3 me-2"
        (click)="viewProgress()"
        style="min-width: 145px"
      >
        {{ "DIALOG_VIEW_PROGRESS" | translate }}
      </button>
    </div>
  </div>
</div>
<div class="mobile">
  <div class="custom-dialog-mobile">
    <h2>{{ "DIALOG_COMP_TITLE" | translate }}!</h2>
    <h3>
      <p>{{ "DIALOG_COMP_CONTENT1" | translate }}</p>
      <p>
        {{ "DIALOG_COMP_CONTENT2" | translate }}
      </p>
    </h3>
    <div class="form-group text-center">
      <button
        class="btn btn-primary field mx-auto col-3 me-2"
        (click)="logJournal()"
        style="min-width: 145px"
      >
        {{ "DIALOG_LOG_JOURNAL" | translate }}
      </button>
      <br /><br />
      <button
        class="btn btn-primary field mx-auto col-3 me-2"
        (click)="viewProgress()"
        style="min-width: 145px"
      >
        {{ "DIALOG_VIEW_PROGRESS" | translate }}
      </button>
    </div>
  </div>
</div>
