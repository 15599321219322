<div class="card-header-date content-box-tsi notes">
  <h3 *ngIf="this.Role == userRole" id="header-text">
    {{ "DIARY_DOCTOR_NOTE" | translate }}
  </h3>

  <div *ngIf="this.Role == 'Admin'; else elseBlock">
    <form class="p-4" *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label>{{ "DIARY_YOUR_NOTES" | translate }}</label>
            <input
              type="text"
              id="notes"
              formControlName="note"
              class="form-control"
              [ngClass]="{ 'is-invalid': submitted && f.note.errors }"
            />
            <div *ngIf="submitted && f.note.errors" class="invalid-feedback">
              <div *ngIf="f.note.errors.required">Field is required</div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group form-action-container">
            <button
              type="submit"
              class="btn btn-primary rounded-pill w-25 my-2"
            >
              {{ "DIARY_SUBMIT" | translate }}
            </button>
            <button
              type="button"
              (click)="onReset()"
              class="btn btn-secondary rounded-pill w-25 my-2 reset-button mx-4"
            >
              {{ "DIARY_RESET_FORM" | translate }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <ng-template #elseBlock>
    <p class="note">
      {{ doctorNote }}
    </p>
    <p class="signature">
      {{ signDate }}
    </p>
  </ng-template>
</div>
