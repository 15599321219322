<div class="desktop">
  <div class="custom-dialog">
    <h2 *ngIf="data.flg == 1 || data.flg == 3">
      {{ "DIALOG_INCOMPLETE_JOURNAL" | translate }}
    </h2>
    <h2 *ngIf="data.flg == 2">{{ "DIALOG_COURSE_UNLOCK" | translate }}</h2>
    <h3>
      <div *ngIf="data.flg == 1 || data.flg == 3">
        <p>
          <!-- You haven't logged your Sleep Journal for the following dates: -->
          {{ "DIALOG_NOT_LOG_JOURNAL" | translate }}
        </p>
        <b>
          <ul>
            <li *ngFor="let date of data.missingDates">
              <!-- <a href="#" (click)="selectDate($event, date)">{{
              date | date : "yyyy-MM-dd"
            }}</a> -->
              <a href="#" (click)="selectDate($event, date)">{{ date }}</a>
            </li>
          </ul>
        </b>

        <p>
          <!-- Please complete your logs by clicking on the dates above or using the
        Log Journal button. -->
          {{ "DIALOG_COMPLETE_LOG_JOURNAL" | translate }}
        </p>
      </div>
      <div *ngIf="data.flg == 1">
        <div [innerHTML]="safeHtmlContentNext"></div>
      </div>
      <div *ngIf="data.flg == 2">
        <div [innerHTML]="saftHtmlContent"></div>
        <p>
          <b>
            <!-- Stay consistent, you're almost there! -->
            {{ "DIALOG_STAY_CONSISTENT" | translate }}
          </b>
        </p>
      </div>
    </h3>
    <div class="form-group text-center">
      <button
        *ngIf="data.flg == 1 || data.flg == 3"
        class="btn btn-primary field mx-auto col-3 me-2"
        (click)="logJournal()"
        style="min-width: 145px"
      >
        {{ "DIALOG_LOG_JOURNAL" | translate }}
      </button>
      <button
        *ngIf="data.flg == 2"
        class="btn btn-primary field mx-auto col-3 me-2"
        (click)="viewProgress()"
        style="min-width: 145px"
      >
        {{ "DIALOG_VIEW_PROGRESS" | translate }}
      </button>

      <button
        class="btn btn-primary field mx-auto col-3 ms-2"
        (click)="onCancel()"
        style="min-width: 145px"
      >
        {{ "DIALOG_CANCEL" | translate }}
      </button>
    </div>
  </div>
</div>
<div class="mobile">
  <div class="custom-dialog-mobile">
    <h2 *ngIf="data.flg == 1 || data.flg == 3">
      {{ "DIALOG_INCOMPLETE_JOURNAL" | translate }}
    </h2>
    <h2 *ngIf="data.flg == 2">{{ "DIALOG_COURSE_UNLOCK" | translate }}</h2>
    <h3>
      <div *ngIf="data.flg == 1 || data.flg == 3">
        <p>
          <!-- You haven't logged your Sleep Journal for the following dates: -->
          {{ "DIALOG_NOT_LOG_JOURNAL" | translate }}
        </p>
        <b>
          <ul>
            <li *ngFor="let date of data.missingDates">
              <!-- <a href="#" (click)="selectDate($event, date)">{{
              date | date : "yyyy-MM-dd"
            }}</a> -->
              <a href="#" (click)="selectDate($event, date)">{{ date }}</a>
            </li>
          </ul>
        </b>

        <p>
          <!-- Please complete your logs by clicking on the dates above or using the
        Log Journal button. -->
          {{ "DIALOG_COMPLETE_LOG_JOURNAL" | translate }}
        </p>
      </div>
      <div *ngIf="data.flg == 1">
        <div [innerHTML]="safeHtmlContentNext"></div>
      </div>
      <div *ngIf="data.flg == 2">
        <div [innerHTML]="saftHtmlContent"></div>
        <p>
          <b>
            <!-- Stay consistent, you're almost there! -->
            {{ "DIALOG_STAY_CONSISTENT" | translate }}
          </b>
        </p>
      </div>
    </h3>
    <div class="form-group text-center">
      <button
        *ngIf="data.flg == 1 || data.flg == 3"
        class="btn btn-primary field mx-auto col-3 me-2"
        (click)="logJournal()"
        style="min-width: 145px"
      >
        {{ "DIALOG_LOG_JOURNAL" | translate }}
      </button>
      <button
        *ngIf="data.flg == 2"
        class="btn btn-primary field mx-auto col-3 me-2"
        (click)="viewProgress()"
        style="min-width: 145px"
      >
        {{ "DIALOG_VIEW_PROGRESS" | translate }}
      </button>
      <br /><br />
      <button
        class="btn btn-primary field mx-auto col-3 ms-2"
        (click)="onCancel()"
        style="min-width: 145px"
      >
        {{ "DIALOG_CANCEL" | translate }}
      </button>
    </div>
  </div>
</div>
