import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Role } from '@app/_models';
import { AccountService, AlertService } from '@app/_services';
import { PatientService } from '@app/_services/patient.service';
import { first } from 'rxjs/operators';

@Component({
  templateUrl: 'notes.component.html',
  selector: 'diarynotes',
  styleUrls: ['notes.style.scss'],
})
export class DiaryNotesComponent implements OnInit {
  //realistically both of these are passed from somewhere

  doctorNote = '-';
  signDate = '-';

  userRole = Role.User;
  adminRole = Role.Admin;
  Role: Role;
  form: FormGroup;
  submitted = false;

  constructor(
    private patientService: PatientService,
    private accountService: AccountService,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.Role = this.accountService.Role;
    let patientData = this.patientService.patientData;
    if (patientData && patientData.profile_details) {
      this.doctorNote = patientData?.profile_details?.notes;
      this.signDate =
        'Signed by ' + patientData?.profile_details?.notes_written_by;
    }
    this.form = this.formBuilder.group({
      note: ['', Validators.required],
    });
    this.form.patchValue({
      note: this.doctorNote,
    });
    console.log(this.Role);
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    /* if (this.form.invalid) {
      return;
    }
  */
    let note = this.form.value.note;

    let userData = JSON.parse(localStorage.getItem('token'));

    console.log(JSON.stringify(this.form.value, null, 2));
    this.patientService
      .savePatientNote(note, userData.user.first_name)
      .pipe(first())
      .subscribe({
        next: (data) => {
          this.alertService.info('Successfully updated the data');
          window.location.reload();
        },
        error: (error) => {
          this.alertService.error(error);
        },
      });
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }
}
