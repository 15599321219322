import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.less'],
})
export class RangeSliderComponent implements OnInit {
  @Input()
  currentDate: Date = new Date();

  @Input()
  selectedDate: any = new Date().toJSON().slice(0, 10);

  @Input()
  selectedMonth: any = new Date();

  @Input()
  noTheme: boolean = false;
  constructor(private translate: TranslateService) {}

  @Input()
  type = 'daily';

  @Input()
  name = 'selectw';

  today = new Date();
  savedLang;
  ngOnInit(): void {
    var curr = new Date();
    var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
    this.currentDate = firstday;
    this.selectedMonth = moment(this.currentDate).format('YYYY-MM');

    if (localStorage.getItem('selectedLanguage')) {
      this.savedLang = localStorage.getItem('selectedLanguage');
    } else {
      this.savedLang = this.translate.defaultLang;
    }
    this.translate.use(this.savedLang);

    this.translate.onLangChange.subscribe((event) => {
      localStorage.setItem('selectedLanguage', event.lang);
      this.savedLang = event.lang;
    });
  }
  @Output() onDatePicked = new EventEmitter<any>();
  @Output() onWeekShift = new EventEmitter<any>();

  addDays(date, days) {
    if (isFinite(date) && date instanceof Date) {
      //  find = new Date(Number(date));
    } else {
      date = new Date(date);
    }
    const find = new Date(Number(date));
    find.setDate(date.getDate() + days);
    return find;
  }

  addMonths(date, months) {
    const find = new Date(Number(date));
    find.setMonth(find.getMonth() + months);
    return find;
  }

  shift(attr) {
    if (this.type == 'monthly') {
      if (attr == -1) {
        this.currentDate = this.addMonths(this.currentDate, -7);
        this.selectedDate = this.currentDate;
      } else {
        this.currentDate = this.addMonths(this.currentDate, 7);
        this.selectedDate = this.currentDate;
      }
      this.selectedMonth = moment(this.currentDate).format('YYYY-MM');
      this.onWeekShift.emit(this.currentDate);
    } else {
      if (attr == -1) {
        this.currentDate = this.addDays(this.currentDate, -7);
      } else {
        this.currentDate = this.addDays(this.currentDate, 7);
      }
      this.selectedDate = moment(this.currentDate).format('YYYY-MM-DD');
      this.onWeekShift.emit(this.currentDate);
    }
  }

  change() {
    if (this.type != 'monthly') {
      this.onDatePicked.emit(this.selectedDate);
    } else {
      this.onDatePicked.emit(this.selectedMonth);
    }
  }

  getChineseDay(date: Date): string {
    const days = ['日', '一', '二', '三', '四', '五', '六'];
    return days[date.getDay()];
  }

  addChineseDays(date: Date, days: number): Date {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }

  getChineseMonth(date: Date): string {
    const months = [
      '一月',
      '二月',
      '三月',
      '四月',
      '五月',
      '六月',
      '七月',
      '八月',
      '九月',
      '十月',
      '十一月',
      '十二月',
    ];
    return months[date.getMonth()];
  }
}
