import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
// used to create fake backend
import { fakeBackendProvider } from './_helpers';

import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor, appInitializer } from './_helpers';
import { AccountService } from './_services';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';

import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatTimepickerModule } from 'mat-timepicker';
import { MatSliderModule } from '@angular/material/slider';

import { MatIconModule } from '@angular/material/icon';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { Step1Component } from './onboarding/step1/step1.component';
import { Step2Component } from './onboarding/step2/step2.component';
import { Step3Component } from './onboarding/step3/step3.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { DatePipe } from '@angular/common';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { ContactusComponent } from './contactus/contactus.component';
import { CbtiComponent } from './cbti/cbti.component';
import { DiaryModule } from './diary/diary.module';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { CourseService } from './_services/course.service';
import { ConfirmDialogComponent } from './intro/intro-page3/confirm-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { JournalModule } from './journal/journal.module';

import { DialogComponent } from './dialog/dialog.component';
import { MissingJournalDialogComponent } from './dialog/missing-journal-dialog/missing-journal-dialog.component';
import { LongTimeConfirmDialogComponent } from './dialog/long-time-confirm-dialog/long-time-confirm-dialog.component';
import { TimePickerDialogComponent } from './dialog/time-picker-dialog/time-picker-dialog.component';
import { DateTimePickerDialogComponent } from './dialog/date-time-picker-dialog/date-time-picker-dialog.component';
import { HourMinuterPickerDialogComponent } from './dialog/hour-minuter-picker-dialog/hour-minuter-picker-dialog.component';
import { JournalConfirmDialogComponent } from './journal/journal-confirm-dialog.component';

import { AddPatientComponent } from './dashboard/patient/patient.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CompleteDialogComponent } from './dialog/complete-dialog/complete-dialog.component';
// import { PatientProfileComponent } from './dashboard/patient-profile/patient-profile.component';
import { Week1Module } from './week1/week1.module';
import { Week2Module } from './week2/week2.module';
import { Week3Module } from './week3/week3.module';
import { Week4Module } from './week4/week4.module';
import { Week5Module } from './week5/week5.module';
import { IntroModule } from './intro/intro.module';
// import { InjectionService } from '@swimlane/ngx-charts/lib/common/tooltip/injection.service';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CourseModule } from './course/course.module';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    TourMatMenuModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    RouterModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatTimepickerModule,
    MatIconModule,
    NgxSliderModule,
    MatExpansionModule,
    MatSliderModule,
    NgScrollbarModule,
    // DiaryModule,
    FormsModule,
    MatDialogModule,
    JournalModule,
    AngularEditorModule,
    IntroModule,
    Week1Module,
    Week2Module,
    Week3Module,
    Week4Module,
    Week5Module,
    NgxChartsModule,
    CourseModule,
    // ngx-translate
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],

  declarations: [
    AppComponent,
    AlertComponent,
    OnboardingComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    ContactusComponent,
    CbtiComponent,
    ConfirmDialogComponent,
    DialogComponent,
    MissingJournalDialogComponent,
    LongTimeConfirmDialogComponent,
    TimePickerDialogComponent,
    DateTimePickerDialogComponent,
    HourMinuterPickerDialogComponent,
    JournalConfirmDialogComponent,
    AddPatientComponent,
    DashboardComponent,
    CompleteDialogComponent,
  ],
  providers: [
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializer,
    //   multi: true,
    //   deps: [AccountService],
    // },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    fakeBackendProvider,
    // InjectionService,
    DatePipe,
    CourseService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
