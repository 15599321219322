<div class="p-4 container-custom outer">
  <h1
    style="
      color: #012233;
      margin-bottom: 10px !important;
      font-size: 28px !important;
    "
  >
    <b>{{ "INTRO_P2_HOW_WORK" | translate }} </b>
  </h1>
  <h4 style="color: #002e46; font-size: 18px; align-content: left">
    <br />

    <p>
      {{ "INTRO_P2_HOW_WORK_ANSWER" | translate }}
    </p>
  </h4>
  <!-- <div class="program-steps">
    <div class="step-card" *ngFor="let step of steps">
      <div class="step-number" [ngStyle]="{ 'background-color': step.color }">
        {{ step.number }}
      </div>
      <div class="step-content">
        <h4>{{ step.title | translate }}</h4>
        <p>
          {{ step.description | translate }}
        </p>
      </div>
    </div>
  </div> -->

  <div class="container">
    <!-- Block 1 -->
    <div class="block">
      <div class="number-block red">
        <span>01</span>
        <div class="triangle light-red"></div>
      </div>
      <div class="text-content">
        <h3>{{ "INTRO_P2_SELF_EVALUATION" | translate }}</h3>
        <p>
          {{ "INTRO_P2_SELF_EVALUATION_DETAILS" | translate }}
        </p>
      </div>
    </div>

    <!-- Block 2 -->
    <div class="block">
      <div class="number-block orange">
        <span>02</span>
        <div class="triangle"></div>
      </div>
      <div class="text-content">
        <h3>{{ "INTRO_P2_EDUCATION" | translate }}</h3>
        <p>
          {{ "INTRO_P2_EDUCATION_DETAILS" | translate }}
        </p>
      </div>
    </div>

    <!-- Block 3 -->
    <div class="block">
      <div class="number-block green">
        <span>03</span>
        <div class="triangle"></div>
      </div>
      <div class="text-content">
        <h3>{{ "INTRO_P2_SELF_REFLECTION" | translate }}</h3>
        <p>
          {{ "INTRO_P2_SELF_REFLECTION_DETAILS" | translate }}
        </p>
      </div>
    </div>
  </div>

  <div class="form-group text-center">
    <button class="btn btn-primary field mx-auto col-3" (click)="onNextClick()">
      {{ "NEXT" | translate }}
    </button>
  </div>
</div>
