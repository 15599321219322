import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { Week5Component } from './week5.component';
import { Week5Page1Component } from './week5-page1/week5-page1.component';
import { Week5Page2Component } from './week5-page2/week5-page2.component';
import { Week5Page3Component } from './week5-page3/week5-page3.component';
import { Week5Page4Component } from './week5-page4/week5-page4.component';
import { Week5Page5Component } from './week5-page5/week5-page5.component';
import { Week5Page6Component } from './week5-page6/week5-page6.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AngularEditorModule,
    RouterModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    Week5Component,
    Week5Page1Component,
    Week5Page2Component,
    Week5Page3Component,
    Week5Page4Component,
    Week5Page5Component,
    Week5Page6Component,
  ],
  exports: [Week5Page2Component],
})
export class Week5Module {}
