import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Administrator } from '@app/_models/administrator';

const baseUrl = `${environment.apiUrl}/api`;
@Injectable({ providedIn: 'root' })
export class AdministratorService {
  private AdministratorSubject: BehaviorSubject<Administrator>;
  public account: Observable<Administrator>;

  limit: number = 10;
  offset: number = 0;
  count: number = 0;

  startPage: number = 1;
  printPageList: any;

  constructor(private http: HttpClient) {
    this.AdministratorSubject = new BehaviorSubject<Administrator>(null);
    this.account = this.AdministratorSubject.asObservable();
  }

  gePagiantion() {
    this.printPageList = [];
    let startPoint;
    let endPoint;

    let lastPage = Math.floor(this.count / this.limit);
    let startPage = this.startPage;

    if (startPage == lastPage) {
      startPoint = startPage - 2;
      endPoint = lastPage;
    } else if (startPage == 1) {
      startPoint = 1;
      endPoint = startPoint + 2;
    } else {
      startPoint = startPage - 1;
      endPoint = startPage + 1;
    }

    if (startPoint <= 0) {
      startPoint = 1;
    }

    if (endPoint > lastPage) {
      endPoint = lastPage;
    }

    for (let i = startPoint; i <= endPoint; i++) {
      this.printPageList.push(i);
    }
  }

  getAdministratorDetails() {
    let { limit, offset } = this;

    return this.http
      .get<any>(`${baseUrl}/admin/?limit=${limit}&offset=${offset}`)
      .pipe(
        map((AdministratorDetails) => {
          this.AdministratorSubject.next(AdministratorDetails);
          this.count = AdministratorDetails.count;
          this.gePagiantion();
          return AdministratorDetails;
        })
      );
  }

  getAdministratorDetailsById(id: Number) {
    return this.http.get<any>(`${baseUrl}/admin/${id}/`).pipe(
      map((AdministratorDetails) => {
        this.AdministratorSubject.next(AdministratorDetails);
        return AdministratorDetails;
      })
    );
  }

  moveToNextPage() {
    this.startPage = this.startPage + 1;
    this.offset = (this.startPage - 1) * this.limit;
    return this.getAdministratorDetails();
  }

  moveToPreviousPage() {
    this.startPage = this.startPage - 1;
    let offset = (this.startPage - 1) * this.limit;
    if (offset < 0) {
      offset = 0;
    }

    this.offset = offset;
    return this.getAdministratorDetails();
  }

  goToPage(index) {
    this.startPage = index;
    let offset = (this.startPage - 1) * this.limit;
    if (offset < 0) {
      offset = 0;
    }

    this.offset = offset;
    return this.getAdministratorDetails();
  }

  get isPreviousPageDisable() {
    return this.offset - this.limit < 0;
  }

  get isNextPageDisable() {
    let { limit, offset, count } = this;
    return limit * offset + limit >= count;
  }

  resetPaginationDetails() {
    this.offset = 0;
    this.count = 0;
  }
}
