<div class="desktop">
  <div class="custom-dialog">
    <h2>{{ "DIALOG_CONFIRMATION" | translate }}</h2>
    <h3>
      <p>{{ "INTRO_DIALOG_CONFIRM_INFO" | translate }}</p>
    </h3>
    <div class="form-group text-center">
      <button
        class="btn btn-primary field mx-auto col-3 me-2"
        (click)="onConfirm()"
      >
        {{ "DIALOG_CONFIRM" | translate }}
      </button>
      <button
        class="btn btn-primary field mx-auto col-3 ms-2"
        (click)="onCancel()"
      >
        {{ "DIALOG_CANCEL" | translate }}
      </button>
    </div>
  </div>
</div>
<div class="mobile">
  <div class="custom-dialog-mobile">
    <h2>{{ "DIALOG_CONFIRMATION" | translate }}</h2>
    <h3>
      <p>{{ "INTRO_DIALOG_CONFIRM_INFO" | translate }}</p>
    </h3>
    <div class="form-group text-center">
      <button
        class="btn btn-primary field mx-auto col-3 me-2"
        (click)="onConfirm()"
        style="min-width: 100px"
      >
        {{ "DIALOG_CONFIRM" | translate }}
      </button>
      <br /><br />
      <button
        class="btn btn-primary field mx-auto col-3 ms-2"
        (click)="onCancel()"
        style="min-width: 100px"
      >
        {{ "DIALOG_CANCEL" | translate }}
      </button>
    </div>
  </div>
</div>
