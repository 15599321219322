import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IntroComponent } from './intro.component';
import { IntroPage1Component } from './intro-page1/intro-page1.component';
import { IntroPage2Component } from './intro-page2/intro-page2.component';
import { IntroPage3Component } from './intro-page3/intro-page3.component';

import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    IntroComponent,
    IntroPage1Component,
    IntroPage2Component,
    IntroPage3Component,
  ],
})
export class IntroModule {}
