import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError } from 'rxjs';

import { environment } from '@environments/environment';
import { AlertService } from './alert.service';

import { utcToZonedTime } from 'date-fns-tz';
import { DatePipe } from '@angular/common';
import { MissingJournalDialogComponent } from '@app/dialog/missing-journal-dialog/missing-journal-dialog.component';
import { LongTimeConfirmDialogComponent } from '@app/dialog/long-time-confirm-dialog/long-time-confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { PatientService } from './patient.service';

const courseBaseUrl = `${environment.apiUrl}/course`;

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  private weeksLocked: boolean[] = [true, true, true, true, true, true];
  private week1ContendLocked: boolean[] = [true, true, true, true, true];
  private week2ContendLocked: boolean[] = [
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ];
  private week3ContendLocked: boolean[] = [true, true, true, true, true];
  private week4ContendLocked: boolean[] = [true, true, true, true, true];
  private week5ContendLocked: boolean[] = [true, true, true, true, true, true];
  private week6ContendLocked: boolean[] = [true, true];

  private currentWeek: number = 0;
  private currentPage: number = 0;
  private timeZone = 'America/Vancouver';
  private form: FormGroup;

  constructor(
    private http: HttpClient,
    private alertService: AlertService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder,
    private patientService: PatientService
  ) {
    this.loadState();
    this.form = this.fb.group({
      entry_date: [''],
    });
  }

  saveUserProgress(progress: { currentWeek: number; currentPage: number }) {
    return this.http.post(`${courseBaseUrl}/save-progress/`, progress);
  }

  getUserProgress(): Observable<any> {
    return this.http.get(`${courseBaseUrl}/get-progress/`).pipe(
      map((response: any) => {
        return response || null;
      })
    );
  }
  // deleteUserProgressById(id: number) {
  //   return this.http.delete(`${courseBaseUrl}/delete-progress/${id}/`);
  // }

  logicallyDeleteUserData(): Observable<any> {
    return this.http.post(`${courseBaseUrl}/logically-delete-user-data/`, {});
  }

  getJournalEntries(unlockWeekTime: Date): Observable<any> {
    let params = new HttpParams();
    const startTime = this.datePipe.transform(unlockWeekTime, 'yyyy-MM-dd');

    const endDate = new Date(unlockWeekTime);
    endDate.setDate(unlockWeekTime.getDate() + 6);
    const endTime = this.datePipe.transform(endDate, 'yyyy-MM-dd');
    params = params.append('start_date', startTime);
    params = params.append('end_date', endTime);
    return this.http
      .get(`${courseBaseUrl}/check-journal-entries/`, {
        params: params,
      })
      .pipe(
        map((response: any) => {
          if (!response || response.length === 0) {
            return null;
          } else {
            return response;
          }
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            return of(null);
          }
          return this.handleError(error);
        })
      );
  }

  getSleepJournal(entryTime: Date): Observable<any> {
    let params = new HttpParams();
    const entryDate = this.datePipe.transform(entryTime, 'yyyy-MM-dd');
    params = params.append('entry_date', entryDate);
    return this.http.get(`${courseBaseUrl}/get-sleep-journal/`, {
      params: params,
    });
  }

  getLastWeekExpectedUnlockTime(progress: any, weekNumber: number): Date {
    let lastWeekExpectedUnlockTime = '';
    switch (weekNumber) {
      case 1:
        lastWeekExpectedUnlockTime = progress.week1_expected_unlock_time;
        break;
      case 2:
        lastWeekExpectedUnlockTime = progress.week2_expected_unlock_time;
        break;
      case 3:
        lastWeekExpectedUnlockTime = progress.week3_expected_unlock_time;
        break;
      case 4:
        lastWeekExpectedUnlockTime = progress.week4_expected_unlock_time;
        break;
    }
    const unlockWeekTimeVancouver = utcToZonedTime(
      lastWeekExpectedUnlockTime,
      this.timeZone
    );

    return unlockWeekTimeVancouver;
  }

  getLastWeekJournalEntries(weekNumber: number): Observable<any> {
    return this.getUserProgress().pipe(
      switchMap((progress: any) => {
        if (progress) {
          const unlockWeekTimeVancouver = this.getLastWeekExpectedUnlockTime(
            progress,
            weekNumber
          );

          let params = new HttpParams();
          const startTime = this.datePipe.transform(
            unlockWeekTimeVancouver,
            'yyyy-MM-dd'
          );

          const endDate = new Date(unlockWeekTimeVancouver);
          endDate.setDate(unlockWeekTimeVancouver.getDate() + 6);
          const endTime = this.datePipe.transform(endDate, 'yyyy-MM-dd');
          params = params.append('start_date', startTime);
          params = params.append('end_date', endTime);
          return this.http
            .get(`${courseBaseUrl}/get-journal-entries/`, {
              params: params,
            })
            .pipe(
              map((response: any) => {
                if (!response || response.length === 0) {
                  return null;
                } else {
                  return response;
                }
              }),
              catchError((error: HttpErrorResponse) => {
                if (error.status === 404) {
                  return of(null);
                }
                return this.handleError(error);
              })
            );
        } else {
          console.log('No user progress found');
          return of([]);
        }
      }),
      catchError((err) => {
        console.error('Error fetching user progress:', err);
        return of([]);
      })
    );
  }

  getLastWeekSleepJournalCalculateEntries(
    weekNumber: number,
    id: number
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('week_number', weekNumber);
    params = params.append('id', id);
    return this.http.get(
      `${courseBaseUrl}/get-sleep-journal-calculate-entries/`,
      {
        params: params,
      }
    );
  }

  getLastWeekSleepJournalCalculateTotal(
    weekNumber: number,
    id: number
  ): Observable<any> {
    let params = new HttpParams();
    params = params.append('week_number', weekNumber);
    params = params.append('id', id);
    return this.http.get(
      `${courseBaseUrl}/get-sleep-journal-calculate-total/`,
      {
        params: params,
      }
    );
  }

  checkUserProgress(
    currentWeek: number,
    dayDifference: number,
    isFromCourse: boolean
  ) {
    // The difference between current_date and last_update_date is at least 7 days
    if (dayDifference >= 7) {
      const dialogRef = this.dialog.open(LongTimeConfirmDialogComponent, {
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((response) => {
        if (response == 'continue') {
          const progress = {
            currentWeek: currentWeek,
          };
          this.updateCourseTime(progress).subscribe({
            next: () => {
              // Add from week 1 - 5
              if (currentWeek === 1) {
                this.router.navigate(['/week1/track_your_sleep']);
              } else if (currentWeek === 2) {
                this.router.navigate(['/week2/weekly_check_in']);
              } else if (currentWeek === 3) {
                this.router.navigate(['/week3/weekly_check_in']);
              } else if (currentWeek === 4) {
                this.router.navigate(['/week4/weekly_check_in']);
              } else if (currentWeek === 5) {
                this.router.navigate(['/week5/weekly_check_in']);
              }
            },
            error: (error) => {
              this.alertService.error(
                'Failed to save progress:' + error.message
              );
            },
          });
        } else if (response == 'reset') {
          this.logicallyDeleteUserData().subscribe(
            // this.deleteUserProgressById(id).subscribe(
            (response) => {
              localStorage.removeItem('selectedLanguage');
              localStorage.removeItem('weeksLocked');
              localStorage.removeItem('isW1P1VideoWatched');
              localStorage.removeItem('isW1P2VideoWatched');
              localStorage.removeItem('isW1P3VideoWatched');
              localStorage.removeItem('isW2P3VideoWatched');
              localStorage.removeItem('isW2P4VideoWatched');
              localStorage.removeItem('isW2P5VideoWatched');
              localStorage.removeItem('isW2P6VideoWatched');
              localStorage.removeItem('isW3P3VideoWatched');
              localStorage.removeItem('isW4P3VideoWatched');
              localStorage.removeItem('isW5P3VideoWatched');
              this.weeksLocked = [true, true, true, true, true, true];
              this.week1ContendLocked = [true, true, true, true, true];
              this.week2ContendLocked = [
                true,
                true,
                true,
                true,
                true,
                true,
                true,
                true,
              ];
              this.week3ContendLocked = [true, true, true, true, true];
              this.week4ContendLocked = [true, true, true, true, true];
              this.week5ContendLocked = [true, true, true, true, true, true];
              this.week6ContendLocked = [true, true];
              this.router.navigate(['/']);
            },
            (error) => {
              console.error('Error during logical deletion:', error);
              this.alertService.error(
                'An error occurred while deleting user data.'
              );
            }
          );
        }
      });
    } else {
      // The difference between current_date and last_update_date is less than 7 days
      this.checkJournalEntries().subscribe((result) => {
        let missingDates: string[] = [];
        let filledDates: string[] = [];

        switch (result.success) {
          case 1:
            // The difference between current_date and week1_expected_unlock_time is less than 7 days and
            // there are incomplete Sleep Journals
            missingDates = result.missingDates;
            filledDates = result.filledDates;
            const dialogRef1 = this.dialog.open(MissingJournalDialogComponent, {
              data: {
                missingDates: missingDates,
                flg: 1,
                date: result.endDate,
              },
              disableClose: true,
            });
            dialogRef1.afterClosed().subscribe((result) => {
              if (result === 'confirm') {
                this.router.navigate(['/journal']);
              }
            });
            break;

          case 2:
            // The difference between current_date and week1_expected_unlock_time is less than 7 days and
            // there are no incomplete Sleep Journals
            filledDates = result.filledDates;
            const dialogRef2 = this.dialog.open(MissingJournalDialogComponent, {
              data: {
                missingDates: missingDates,
                flg: 2,
                date: result.endDate,
              },
            });
            dialogRef2.afterClosed().subscribe((result) => {
              if (result === 'progress') {
                this.router.navigate(['/diary']);
              }
            });
            break;

          case 3:
            // The difference between current_date and week1_expected_unlock_time is at lease 7 days and
            // there are incomplete Sleep Journals
            missingDates = result.missingDates;
            filledDates = result.filledDates;

            const dialogRef3 = this.dialog.open(MissingJournalDialogComponent, {
              data: {
                missingDates: missingDates,
                flg: 3,
              },
              disableClose: true,
            });
            dialogRef3.afterClosed().subscribe((result) => {
              if (result === 'confirm') {
                this.router.navigate(['/journal']);
              }
            });
            break;

          case 4:
            // The difference between current_date and week_expected_unlock_time is at lease 7 days and
            // there are not incomplete Sleep Journals

            if (isFromCourse) {
              this.updateProgress(currentWeek + 1, 1);
              // Add from week2 -5
              if (currentWeek === 1) {
                this.unlockWeek(1);
                this.unlockWeek2Content(0);
                this.router.navigate(['week2/weekly_check_in']);
              } else if (currentWeek === 2) {
                this.unlockWeek(2);
                this.unlockWeek3Content(0);
                this.updateProgress(3, 1);
                this.router.navigate(['week3/weekly_check_in']);
              } else if (currentWeek === 3) {
                this.unlockWeek(3);
                this.unlockWeek4Content(0);
                this.updateProgress(4, 1);
                this.router.navigate(['week4/weekly_check_in']);
              } else if (currentWeek === 4) {
                this.unlockWeek(4);
                this.unlockWeek5Content(0);
                this.updateProgress(5, 1);
                this.router.navigate(['week5/weekly_check_in']);
              } else if (currentWeek === 5) {
                this.unlockWeek(5);
                this.unlockWeek6Content(0);
                this.updateProgress(6, 1);
                this.router.navigate(['week5/complete_course']);
              }
            }

            break;
        }
      });
    }
  }

  updateCourseTime(progress: { currentWeek: number }) {
    return this.http.post(`${courseBaseUrl}/update-course-time/`, progress);
  }

  updateLastLoginTime(progress: { currentWeek: number }) {
    return this.http.post(`${courseBaseUrl}/update-last-login-time/`, progress);
  }

  saveSelfAssessmentReflection(data: any): Observable<any> {
    return this.http.post(`${courseBaseUrl}/save-self-assessment/`, data);
  }

  updateDoctorSuggestion(data: any): Observable<any> {
    return this.http.post(`${courseBaseUrl}/upadate-doctor-suggestion/`, data);
  }

  saveWeeklyCheckIn(data: any): Observable<any> {
    return this.http.post(`${courseBaseUrl}/save-weekly-checkin/`, data);
  }

  getSelfAssessmentReflection(weekNumber: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('week_number', weekNumber);
    return this.http
      .get(`${courseBaseUrl}/get-self-assessment/`, {
        params: params,
      })
      .pipe(
        map((response: any) => {
          return response || null;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            return of(null);
          } else {
            return this.handleError(error);
          }
        })
      );
  }

  // getSelfReflectInfo(id: number): Observable<any> {
  //   return this.http.get<any>(`${courseBaseUrl}/get-selfreflect-Info/${id}/`);
  // }

  // getJournalTotalInfo(id: number): Observable<any> {
  //   return this.http.get<any>(`${courseBaseUrl}/get-journal-total-Info/${id}/`);
  // }

  // getDesiredTimeInfo(id: number): Observable<any> {
  //   return this.http.get<any>(`${courseBaseUrl}/get-desired-time-Info/${id}/`);
  // }

  // getCourseDetailsData(id: number): Observable<any> {
  //   return forkJoin({
  //     patientCourseDetails: this.patientService.getPatientCourseDetailsById(id),
  //     selfReflectInfo: this.getSelfReflectInfo(id),
  //     sleepJournalTotal: this.getJournalTotalInfo(id),
  //     desiredSleepTime: this.getDesiredTimeInfo(id),
  //   }).pipe(
  //     map((response) => {
  //       return {
  //         patientDetails: response.patientCourseDetails,
  //         selfReflectData: response.selfReflectInfo,
  //         sleepJournalData: response.sleepJournalTotal,
  //         desiredSleepData: response.desiredSleepTime,
  //       };
  //     })
  //   );
  // }

  getWeeklyCheckIn(weekNumber: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('week_number', weekNumber);
    return this.http
      .get(`${courseBaseUrl}/get-weekly-checkin/`, {
        params: params,
      })
      .pipe(
        map((response: any) => {
          return response || null;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            return of(null);
          } else {
            return this.handleError(error);
          }
        })
      );
  }

  convertTimeStringToDate(dateTimeString: string): Date | null {
    const [date, time] = dateTimeString.split('T');
    const [years, months, days] = date.split('-').map(Number);
    const [hours, minutes, seconds] = time.split(':').map(Number);

    if (
      isNaN(years) ||
      isNaN(months) ||
      isNaN(days) ||
      isNaN(hours) ||
      isNaN(minutes) ||
      isNaN(seconds)
    ) {
      return null; // Return null if the time string is invalid
    }

    const newDate = new Date(years, months - 1, days);

    newDate.setHours(hours);
    newDate.setMinutes(minutes);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);

    return newDate;
  }

  saveLastWeekSleepJournalCalculate(entry: any, week_number: number) {
    // Convert the time string to a Date object
    const goToBedTimeDate = this.convertTimeStringToDate(
      entry.go_to_bed_date_time
    );

    const lightsOffTimeDate = this.convertTimeStringToDate(
      entry.lights_off_date_time
    );

    const goToSleepTimeDate = this.convertTimeStringToDate(
      entry.go_to_sleep_date_time
    );

    const wakeUpTimeDate = this.convertTimeStringToDate(
      entry.wake_up_date_time
    );

    const riseFromBedTimeDate = this.convertTimeStringToDate(
      entry.rise_from_bed_date_time
    );

    let request = {
      ...entry,
      week_number: week_number,
      entry_date: moment(entry.entry_date).format('YYYY-MM-DD'),
      go_to_bed_date_time: goToBedTimeDate,
      lights_off_date_time: lightsOffTimeDate,
      falling_sleep_minutes: entry.duration_falling_sleep,
      go_to_sleep_date_time: goToSleepTimeDate,
      awake_number: entry.number_awakening,
      awake_minutes: entry.minutes_awake,
      wake_up_date_time: wakeUpTimeDate,
      rise_from_bed_date_time: riseFromBedTimeDate,
    };

    this.saveSleepJournalCalculate(request).subscribe(
      (response) => {
        console.log("Last week's sleep journal saved sucessfully", response);
      },
      (error) => {
        console.log("Last week's sleep journal saved failure", error);
      }
    );
  }

  saveSleepJournalCalculate(data: any): Observable<any> {
    data.go_to_bed_date_time = this.datePipe.transform(
      data.go_to_bed_date_time,
      'yyyy-MM-dd HH:mm:ss'
    );
    data.lights_off_date_time = this.datePipe.transform(
      data.lights_off_date_time,
      'yyyy-MM-dd HH:mm:ss'
    );
    data.go_to_sleep_date_time = this.datePipe.transform(
      data.go_to_sleep_date_time,
      'yyyy-MM-dd HH:mm:ss'
    );
    data.wake_up_date_time = this.datePipe.transform(
      data.wake_up_date_time,
      'yyyy-MM-dd HH:mm:ss'
    );
    data.rise_from_bed_date_time = this.datePipe.transform(
      data.rise_from_bed_date_time,
      'yyyy-MM-dd HH:mm:ss'
    );

    return this.http.post(
      `${courseBaseUrl}/save-sleep-journal-calculate/`,
      data
    );
  }

  saveDesiredSleepTime(data: any): Observable<any> {
    data.desired_go_to_bed_time = this.datePipe.transform(
      data.desired_go_to_bed_time,
      'HH:mm:ssZZZZZ'
    );
    data.desired_wake_up_time = this.datePipe.transform(
      data.desired_wake_up_time,
      'HH:mm:ssZZZZZ'
    );
    return this.http.post(`${courseBaseUrl}/save-desired-sleep-time/`, data);
  }

  getDesiredSleepTime(weekNumber: number): Observable<any> {
    let params = new HttpParams();
    params = params.append('week_number', weekNumber);
    return this.http.get(`${courseBaseUrl}/get-desired-sleep-time/`, {
      params: params,
    });
  }

  // Error handling
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, body was: ${error.error}`
      );
    }
    return throwError(
      () => new Error('Something went wrong; please try again later.')
    );
  }

  saveLastWeekSleepJournalCalculateTotal(request: any) {
    this.saveSleepJournalCalculateTotal(request).subscribe(
      (response) => {
        console.log(
          "The calculated total result of last week's sleep journal saved sucessfully",
          response
        );
      },
      (error) => {
        console.log(
          "The calculated total result of last week's sleep journal saved failure",
          error
        );
      }
    );
  }

  saveSleepJournalCalculateTotal(data: any): Observable<any> {
    return this.http.post(
      `${courseBaseUrl}/save-sleep-journal-calculate-total/`,
      data
    );
  }

  getCurrentWeek(): number {
    return this.currentWeek;
  }

  getCurrentPage(): number {
    return this.currentPage;
  }

  private saveState(): void {
    localStorage.setItem('weeksLocked', JSON.stringify(this.weeksLocked));
    localStorage.setItem(
      'week1ContendLocked',
      JSON.stringify(this.week1ContendLocked)
    );
    localStorage.setItem(
      'week2ContendLocked',
      JSON.stringify(this.week2ContendLocked)
    );
    localStorage.setItem(
      'week3ContendLocked',
      JSON.stringify(this.week3ContendLocked)
    );
    localStorage.setItem(
      'week4ContendLocked',
      JSON.stringify(this.week4ContendLocked)
    );
    localStorage.setItem(
      'week5ContendLocked',
      JSON.stringify(this.week5ContendLocked)
    );
    localStorage.setItem(
      'week6ContendLocked',
      JSON.stringify(this.week6ContendLocked)
    );
  }

  private loadState(): void {
    this.weeksLocked = JSON.parse(
      localStorage.getItem('weeksLocked') ||
        '[true, true, true, true, true, true]'
    );
    this.week1ContendLocked = JSON.parse(
      localStorage.getItem('week1ContendLocked') ||
        '[true, true, true, true, true, true]'
    );
    this.week2ContendLocked = JSON.parse(
      localStorage.getItem('week2ContendLocked') ||
        '[true, true, true, true, true, true, true, true]'
    );
    this.week3ContendLocked = JSON.parse(
      localStorage.getItem('week3ContendLocked') ||
        '[true, true, true, true, true]'
    );
    this.week4ContendLocked = JSON.parse(
      localStorage.getItem('week4ContendLocked') ||
        '[true, true, true, true, true]'
    );
    this.week5ContendLocked = JSON.parse(
      localStorage.getItem('week5ContendLocked') ||
        '[true, true, true, true, true, true]'
    );
    this.week6ContendLocked = JSON.parse(
      localStorage.getItem('week6ContendLocked') || '[true, true]'
    );
  }

  isFirstLogin(): Promise<boolean> {
    let isFirstLogin: boolean = false;

    return new Promise((resolve, reject) => {
      this.getUserProgress().subscribe((data) => {
        if (data && Object.keys(data).length > 0) {
          isFirstLogin = false;
        } else {
          isFirstLogin = true;
        }
        resolve(isFirstLogin);
      });
    });
  }

  isStudied(actualWeek: number, actualPage: number): Promise<boolean> {
    let isStudied: boolean = false;

    return new Promise((resolve, reject) => {
      this.getUserProgress().subscribe((data) => {
        if (data && Object.keys(data).length > 0) {
          this.currentWeek = data.current_week;
          this.currentPage = data.current_page;

          if (
            (this.currentWeek === actualWeek &&
              this.currentPage > actualPage) ||
            this.currentWeek > actualWeek
          ) {
            isStudied = true;
          }
        }
        resolve(isStudied);
      });
    });
  }

  updateProgress(actualWeek: number, actualPage: number): boolean {
    let isProgressUpdated: boolean = false;

    this.getUserProgress().subscribe(
      (data) => {
        if (data && Object.keys(data).length > 0) {
          this.currentWeek = data.current_week;
          this.currentPage = data.current_page;

          if (
            (this.currentWeek === actualWeek &&
              this.currentPage < actualPage) ||
            this.currentWeek < actualWeek
          ) {
            const progress = {
              currentWeek: actualWeek,
              currentPage: actualPage,
            };

            this.saveUserProgress(progress).subscribe({
              next: () => {
                isProgressUpdated = true;
                // this.alertService.success('Progress saved successfully!');
              },
              error: (error) => {
                this.alertService.error(
                  'Failed to save progress:' + error.message
                );
              },
            });
          }
        } else {
          const progress = {
            currentWeek: 1,
            currentPage: 1,
          };

          this.saveUserProgress(progress).subscribe({
            next: () => {
              this.unlockWeek(0);
              this.unlockWeek1Content(0);
              // this.alertService.success('Progress saved successfully!');
            },
            error: (error) => {
              this.alertService.error(
                'Failed to save progress:' + error.message
              );
            },
          });
        }
      },
      (error) => {
        console.error('Error fetching user progress', error);
      }
    );

    return isProgressUpdated;
  }

  getCourseTimes(): Promise<{
    expectedUnlockWeekTime: string;
    actualUnlockWeekTime: string;
    lastUpdateTime: string;
    currentWeek: number;
  }> {
    let expectedUnlockWeekTime: string = '';
    let actualUnlockWeekTime: string = '';
    let lastUpdateTime: string = '';
    let currentWeek: number;

    return new Promise((resolve, reject) => {
      this.getUserProgress().subscribe((data) => {
        if (data && Object.keys(data).length > 0) {
          if (data.current_week === 1) {
            expectedUnlockWeekTime = data.week1_expected_unlock_time;
            actualUnlockWeekTime = data.week1_actual_unlock_time;
          } else if (data.current_week === 2) {
            expectedUnlockWeekTime = data.week2_expected_unlock_time;
            actualUnlockWeekTime = data.week2_actual_unlock_time;
          } else if (data.current_week === 3) {
            expectedUnlockWeekTime = data.week3_expected_unlock_time;
            actualUnlockWeekTime = data.week3_actual_unlock_time;
          } else if (data.current_week === 4) {
            expectedUnlockWeekTime = data.week4_expected_unlock_time;
            actualUnlockWeekTime = data.week4_actual_unlock_time;
          } else if (data.current_week === 5) {
            expectedUnlockWeekTime = data.week5_expected_unlock_time;
            actualUnlockWeekTime = data.week5_actual_unlock_time;
          }
          lastUpdateTime = data.last_update_time;
          currentWeek = data.current_week;

          resolve({
            expectedUnlockWeekTime,
            actualUnlockWeekTime,
            lastUpdateTime,
            currentWeek,
          });
        } else {
          reject('No data found');
        }
        (error) => {
          reject(error);
        };
      });
    });
  }

  isLoginGapOver7Days(): boolean {
    let isLoginGapOver7Days: boolean = false;

    this.getCourseTimes()
      .then((times) => {
        const lastUpdateTime = times.lastUpdateTime;
        const lastUpdateTimeVancouver = utcToZonedTime(
          lastUpdateTime,
          this.timeZone
        );
        const currentTime = new Date();
        const currentTimeVancouver = utcToZonedTime(currentTime, this.timeZone);
        const timeDifference =
          currentTimeVancouver.getTime() - lastUpdateTimeVancouver.getTime();
        const daysDifference = timeDifference / (1000 * 3600 * 24);
        console.log('daysDifference' + daysDifference);

        if (daysDifference > 7) {
          isLoginGapOver7Days = true;
        }
      })
      .catch((error) => {
        console.error('Error', error);
      });
    return isLoginGapOver7Days;
  }

  getDayDuration(firstDate: Date, secondDate: Date, timeZone: string) {
    const firstDateZone = utcToZonedTime(firstDate, timeZone);
    const secondateZone = utcToZonedTime(secondDate, timeZone);

    const firstDateZoneDateOnly = new Date(
      firstDateZone.getFullYear(),
      firstDateZone.getMonth(),
      firstDateZone.getDate()
    );
    const secondateZoneDateOnly = new Date(
      secondateZone.getFullYear(),
      secondateZone.getMonth(),
      secondateZone.getDate()
    );
    const timeDifference =
      firstDateZoneDateOnly.getTime() - secondateZoneDateOnly.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);
    return daysDifference;
  }

  checkJournalEntries(): Observable<{
    success: number;
    missingDates: string[];
    filledDates: string[];
    endDate: Date;
  }> {
    return new Observable((observer) => {
      this.getCourseTimes()
        .then((times) => {
          const unlockWeekTime = times.expectedUnlockWeekTime;
          const unlockWeekTimeVancouver = utcToZonedTime(
            unlockWeekTime,
            this.timeZone
          );
          this.getJournalEntries(unlockWeekTimeVancouver).subscribe(
            (response) => {
              observer.next({
                success: response.success,
                missingDates: response.missing_dates,
                filledDates: response.filled_dates,
                endDate: response.end_date,
              });
              observer.complete();
            },
            (error) => {
              console.error('Error fetching journal entries', error);
              observer.error(error);
            }
          );
        })
        .catch((error) => {
          console.error('Error', error);
          observer.error(error);
        });
    });
  }

  getWeekLocked(weekIndex: number): boolean {
    return this.weeksLocked[weekIndex];
  }

  unlockWeek(weekIndex: number): void {
    this.weeksLocked[weekIndex] = false;
    this.saveState();
  }

  getWeek1ContentLocked(taskIndex: number): boolean {
    return this.week1ContendLocked[taskIndex];
  }

  unlockWeek1Content(taskIndex: number): void {
    this.week1ContendLocked[taskIndex] = false;
    this.saveState();
  }

  getWeek2ContentLocked(taskIndex: number): boolean {
    return this.week2ContendLocked[taskIndex];
  }

  unlockWeek2Content(taskIndex: number): void {
    this.week2ContendLocked[taskIndex] = false;
    this.saveState();
  }

  getWeek3ContentLocked(taskIndex: number): boolean {
    return this.week3ContendLocked[taskIndex];
  }

  unlockWeek3Content(taskIndex: number): void {
    this.week3ContendLocked[taskIndex] = false;
    this.saveState();
  }

  getWeek4ContentLocked(taskIndex: number): boolean {
    return this.week4ContendLocked[taskIndex];
  }

  unlockWeek4Content(taskIndex: number): void {
    this.week4ContendLocked[taskIndex] = false;
    this.saveState();
  }

  getWeek5ContentLocked(taskIndex: number): boolean {
    return this.week5ContendLocked[taskIndex];
  }

  unlockWeek5Content(taskIndex: number): void {
    this.week5ContendLocked[taskIndex] = false;
    this.saveState();
  }

  getWeek6ContentLocked(taskIndex: number): boolean {
    return this.week6ContendLocked[taskIndex];
  }

  unlockWeek6Content(taskIndex: number): void {
    this.week6ContendLocked[taskIndex] = false;
    this.saveState();
  }

  unlockWeekAndContent(currentWeek: number, currentPage: number) {
    // Unlock the week
    this.weeksLocked = this.weeksLocked.map((_, index) =>
      index < currentWeek ? false : true
    );

    // Unlock the pages of the week
    if (currentWeek === 1) {
      this.week1ContendLocked = this.week1ContendLocked.map((_, index) =>
        index < currentPage ? false : true
      );
    } else if (currentWeek === 2) {
      this.week1ContendLocked = this.week1ContendLocked.map(() => false);
      this.week2ContendLocked = this.week2ContendLocked.map((_, index) =>
        index < currentPage ? false : true
      );
    } else if (currentWeek === 3) {
      this.week1ContendLocked = this.week1ContendLocked.map(() => false);
      this.week2ContendLocked = this.week2ContendLocked.map(() => false);
      this.week3ContendLocked = this.week3ContendLocked.map((_, index) =>
        index < currentPage ? false : true
      );
    } else if (currentWeek === 4) {
      this.week1ContendLocked = this.week1ContendLocked.map(() => false);
      this.week2ContendLocked = this.week2ContendLocked.map(() => false);
      this.week3ContendLocked = this.week3ContendLocked.map(() => false);
      this.week4ContendLocked = this.week4ContendLocked.map((_, index) =>
        index < currentPage ? false : true
      );
    } else if (currentWeek === 5) {
      this.week1ContendLocked = this.week1ContendLocked.map(() => false);
      this.week2ContendLocked = this.week2ContendLocked.map(() => false);
      this.week3ContendLocked = this.week3ContendLocked.map(() => false);
      this.week4ContendLocked = this.week4ContendLocked.map(() => false);
      this.week5ContendLocked = this.week5ContendLocked.map((_, index) =>
        index < currentPage ? false : true
      );
    } else if (currentWeek === 6) {
      this.week1ContendLocked = this.week1ContendLocked.map(() => false);
      this.week2ContendLocked = this.week2ContendLocked.map(() => false);
      this.week3ContendLocked = this.week3ContendLocked.map(() => false);
      this.week4ContendLocked = this.week4ContendLocked.map(() => false);
      this.week5ContendLocked = this.week5ContendLocked.map(() => false);
      this.week6ContendLocked = this.week6ContendLocked.map((_, index) =>
        index < currentPage ? false : true
      );
    }
  }
}
