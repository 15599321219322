import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

const baseUrl = `${environment.apiUrl}/api`;

@Injectable({
  providedIn: 'root',
})
export class EmailVerificationService {
  constructor(private http: HttpClient) {}

  checkEmail(email: string): Observable<any> {
    return this.http.get(`${baseUrl}/check-email/`, { params: { email } });
  }

  sendCode(email: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${baseUrl}/send-code/`, { email }, { headers });
  }

  verifyCode(email: string, code: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(
      `${baseUrl}/verify-code/`,
      { email, code },
      { headers }
    );
  }

  sendResetPasswordEmail(email: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(
      `${baseUrl}/send-reset-password-email/`,
      { email },
      { headers }
    );
  }

  checkToken(token: string): Observable<any> {
    return this.http.get(`${baseUrl}/check-token/${token}`);
  }

  resetPassword(token: string, password: string): Observable<any> {
    return this.http.post(`${baseUrl}/rest-password/${token}`, { password });
  }

  sendSuggestionNotificattion(data: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${baseUrl}/send-suggestion-nofification/`, data, {
      headers,
    });
  }
}
