import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  ElementRef,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { sleep, bed, labels } from './data';
import { ChangeDetectionStrategy } from '@angular/core';

@Component({
  templateUrl: 'barchart.component.html',
  selector: 'barchart',
  styleUrls: ['barchart.style.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarchartComponent implements OnInit, AfterViewInit {
  @Input()
  data;

  screensize() {
    //console.log(window.innerWidth);
    if (window.innerWidth <= 1180 && window.innerWidth > 820) {
      console.log('1');

      return window.innerWidth / 1.3;
    } else if (window.innerWidth <= 820 && window.innerWidth > 450) {
      console.log('2');

      return window.innerWidth / 1.3;
    } else if (window.innerWidth <= 450 && window.innerWidth < 820) {
      console.log('5');

      return window.innerWidth / 1.3;
    } else {
      console.log('3');
      return window.innerWidth / 2.2;
    }
  }

  view: any[] = [this.screensize(), 300];

  @ViewChild('ContainerRef')
  ContainerRef: ElementRef;

  // https://stackoverflow.com/questions/45906260/ngx-charts-set-number-of-max-displayed-labels-ticks-on-xaxis-and-prevent-rotat
  tickCounter: number = 0;
  tickInterval: number = 10;
  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = false;
  showXAxisLabel: boolean = true;

  // https://stackoverflow.com/questions/45906260/ngx-charts-set-number-of-max-displayed-labels-ticks-on-xaxis-and-prevent-rotat
  // https://stackblitz.com/edit/ngx-charts-axistickformatting-fixed?file=src%2Fapp%2Fapp.component.ts
  xAxisLabel: string = '';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = '';
  animations: boolean = true;
  showGridLine: boolean = true;
  // colorScheme = {
  //   domain: ['#58a3ca', '#055d9d'],
  // };
  colorScheme = {
    domain: ['#002642', '#94C4DC'],
  };
  @HostListener('load', ['$event'])
  windowLoadedEvent(event) {
    alert('loaded');
  }

  colorScheme2 = {
    domain: ['#red', '#red'],
  };

  formatDataLabel(value) {
    // return value + '%';
  }

  onResize(event) {
    // debugger;
    let new_width = event.target.innerWidth / 3;
    console.log(new_width);
    this.view = [new_width, 400];
  }
  axisFormat(val: any) {
    alert('adfsadf');
    if (!val.toString().startsWith('Day')) {
      val = ' \u2714' + val + '/10';
    }
    return val; //\2713 \" \u2611"
    // return (++this.tickCounter % this.tickInterval) === 0 ? val : '';
  }

  yAxisFormat(val: any) {
    return val / 60;
  }

  _update(event) {
    console.log('update');
    alert('update');
  }
  constructor() {
    Object.assign(this, { sleep });
    Object.assign(this, { bed });
    Object.assign(this, { labels });
  }

  ngOnInit() {
    // let line = this.getLine(50);
    // // this.d1.nativeElement.querySelector('svg').insertAdjacentElement('beforeend', '<marker id="arrowhead2" viewBox="0 0 60 60" refX="60" refY="30" markerUnits="strokeWidth" markerWidth="16" markerHeight="10" orient="auto"><path d="M 0 0 L 60 30 L 0 60 z" fill="#800000" /> </marker>')
    // // this.d1.nativeElement.querySelector('svg .bar-chart.chart g[ngx-charts-series-vertical]').insertAdjacentElement('beforeend', marker);
    // this
    //     this.ContainerRef;
    //     this.ContainerRef.nativeElement.querySelector('svg').load = function(){
    //       alert('loadded');
    //     }
    //     // debugger;
    //     // document.querySelectorAll('[ng-reflect-type]')[1].getBoundingClientRect().height
    //     // let paths = this.d1.nativeElement.querySelectorAll('path.bar');
    //     let maxHeight = 0;
    // let paths;
    //     paths.forEach(element => {
    //       // let height = element.getBoundingClientRect().height;
    //       let height = element.getPointAtLength(0).y;
    //       if (maxHeight < height) {
    //         maxHeight = height;
    //       }
    //     });
    //     let marker = this.getMarker();
    // let line = this.getLine(50);
    // // this.d1.nativeElement.querySelector('svg').insertAdjacentElement('beforeend', '<marker id="arrowhead2" viewBox="0 0 60 60" refX="60" refY="30" markerUnits="strokeWidth" markerWidth="16" markerHeight="10" orient="auto"><path d="M 0 0 L 60 30 L 0 60 z" fill="#800000" /> </marker>')
    // // this.d1.nativeElement.querySelector('svg .bar-chart.chart g[ngx-charts-series-vertical]').insertAdjacentElement('beforeend', marker);
    // this.ContainerRef.nativeElement.querySelector('svg .bar-chart.chart g[ngx-charts-series-vertical]').insertAdjacentElement('beforeend', line);
  }

  yAxisTickFormatting = (value) => {
    // Must be in this component. See: https://github.com/swimlane/ngx-charts/issues/261
    return 'asd';
  };

  ngOnChanges() {
    // let line = this.getLine(50);
    // // this.d1.nativeElement.querySelector('svg').insertAdjacentElement('beforeend', '<marker id="arrowhead2" viewBox="0 0 60 60" refX="60" refY="30" markerUnits="strokeWidth" markerWidth="16" markerHeight="10" orient="auto"><path d="M 0 0 L 60 30 L 0 60 z" fill="#800000" /> </marker>')
    // // this.d1.nativeElement.querySelector('svg .bar-chart.chart g[ngx-charts-series-vertical]').insertAdjacentElement('beforeend', marker);
    // this.ContainerRef.nativeElement.querySelector('svg .bar-chart.chart g[ngx-charts-series-vertical]').insertAdjacentElement('beforeend', line);
    //   this.ContainerRef.nativeElement.addEventListener('load', () => {
    //     alert('loaded');
    //   });
    //   debugger;
    //   console.log(this.data);
    //   let paths = this.ContainerRef.nativeElement.querySelectorAll('path.bar');
    // this.ContainerRef.nativeElement.querySelector('svg').load = function(){
    //   alert('loaded');
    // }
    // paths.forEach(elm => {
    //   console.log(elm.getBoundingClientRect());
    // });
  }

  public getLine(maxHeight) {
    let newLine = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'line'
    );
    newLine.setAttribute('id', 'line2');
    newLine.setAttribute('x1', '0');
    // newLine.setAttribute('x1', '6%');

    newLine.setAttribute('y1', String(maxHeight) + '%');
    // newLine.setAttribute('y1', '350');

    newLine.setAttribute('x2', '100%');
    // newLine.setAttribute('x2', '94%');

    newLine.setAttribute('y2', String(maxHeight) + '%');
    // newLine.setAttribute('y2', '350');

    newLine.setAttribute('stroke', 'red');
    newLine.setAttribute('marker-end', 'url(#Triangle)');

    return newLine;
  }

  getMarker() {
    var marker = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'marker'
    );
    marker.setAttribute('id', 'Triangle');
    marker.setAttribute('viewBox', '0 0 10 10');
    marker.setAttribute('refX', '0');
    marker.setAttribute('refY', '5');
    marker.setAttribute('markerUnits', 'strokeWidth');
    marker.setAttribute('markerWidth', '4');
    marker.setAttribute('markerHeight', '3');
    marker.setAttribute('orient', 'auto');

    //     <marker id="Square" markerWidth="10" markerHeight="10"
    //     refX="5" refY="5" orient="auto">
    // <path d="M 5,1 L 9,5 5,9 1,5 z" fill="#6a9100"/>
    // </marker>
    var path = document.createElementNS('http;//www.w3.org/2000/svg', 'path');
    // path.setAttribute('d', 'M 0 0 L 10 5 L 0 10 z');
    path.setAttribute('d', 'M 5,1 L 9,5 5,9 1,5 z');
    marker.appendChild(path);

    return marker;
  }

  ngAfterViewInit() {
    // debugger;
    // alert('hi');
    this.ContainerRef.nativeElement
      .querySelector('svg')
      .addEventListener('load', () => {
        let paths =
          this.ContainerRef.nativeElement.querySelectorAll('path.bar');

        paths.forEach((elm) => {
          console.log(elm.getBoundingClientRect());
        });
        // alert('hi');
      });
    // let paths = this.ContainerRef.nativeElement.querySelectorAll('path.bar');

    // paths.forEach(elm => {
    //   console.log(elm.getBoundingClientRect());
    // })
    // debugger;

    let paths = this.ContainerRef.nativeElement.querySelectorAll('path.bar');

    paths.forEach((elm) => {
      console.log(elm.getBoundingClientRect());
    });
  }
}
