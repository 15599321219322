import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { AccountService } from '@app/_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private accountService: AccountService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const account = this.accountService.accountValue;
    if (this.accountService.isLoggedIn()) {
      // check if route is restricted by role
      if (account.user['get_role_type_label'] == 'Doctor') {
        var roles_value = 'Admin';
      } else {
        var roles_value = 'Patient';
      }
      if (route.data.roles && !route.data.roles.includes(roles_value)) {
        // role not authorized so redirect to home page
        this.router.navigate(['/onboarding']);
        return false;
      }
      // this.router.navigate(['/onboarding']);

      // authorized so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    // this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url }});
    this.router.navigate(['/account/login'], {
      queryParams: { returnUrl: '/onboarding' },
    });
    return false;
  }
}
