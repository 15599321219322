import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { Week1Component } from './week1.component';
import { Week1Page1Component } from './week1-page1/week1-page1.component';
import { Week1Page2Component } from './week1-page2/week1-page2.component';
import { Week1Page3Component } from './week1-page3/week1-page3.component';
import { Week1Page4Component } from './week1-page4/week1-page4.component';
import { Week1Page5Component } from './week1-page5/week1-page5.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AngularEditorModule,
    RouterModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    Week1Component,
    Week1Page1Component,
    Week1Page2Component,
    Week1Page3Component,
    Week1Page4Component,
    Week1Page5Component,
  ],
})
export class Week1Module {}
