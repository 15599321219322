import { AbstractControl, ValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

import { CourseService } from '@app/_services/course.service';

export default class Validation {
  constructor(private courseService: CourseService) {}

  static dateShouldNotBeGreaterThanToday(entryDate: string) {
    return (controls: AbstractControl) => {
      const entryDateControl = controls.get(entryDate);

      const entryDateMomentValue = moment(entryDateControl.value, 'MM/DD/YYYY');
      const currentMomentDate = moment().startOf('day');

      if (entryDateMomentValue.isAfter(currentMomentDate)) {
        // Message:Please record your sleep data up to today.
        entryDateControl.setErrors({
          dateShouldNotBeGreaterThanTodayError: 'JOURNAL_RECORD_DATE_TODAY',
        });
      } else {
        if (entryDateControl.hasError('dateShouldNotBeGreaterThanTodayError')) {
          entryDateControl.setErrors(null);
        }
      }
      return null;
    };
  }

  static wakeUpTimeShouldEarlyNow(wakeUpTime: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const wakeUpTimeControl = controls.get(wakeUpTime);

      if (!wakeUpTimeControl?.value) {
        return null; // Skip validation if either value is empty
      }

      const wakeUpTimeMomentValue = moment(
        wakeUpTimeControl.value,
        'MM/DD/YYYY hh:mm A'
      );

      const nowDateTime = moment(new Date(), 'MM/DD/YYYY hh:mm A');
      let hoursDifference = nowDateTime.diff(
        wakeUpTimeMomentValue,
        'hours',
        true
      );
      if (hoursDifference < 0) {
        wakeUpTimeControl.setErrors({
          // Wake-up time should be earlier than the current time.
          wakeUpTimeShouldEarlyNowError: 'JOURNAL_WAKEUP_TIME_BEFORE_NOW',
        });
      } else {
        if (wakeUpTimeControl.hasError('wakeUpTimeShouldEarlyNowError')) {
          wakeUpTimeControl.setErrors(null);
        }
      }

      return null;
    };
  }

  static wakeUpTimeCompEntry(
    entryDate: string,
    wakeUpTime: string
  ): ValidatorFn {
    return (controls: AbstractControl) => {
      const entryDateControl = controls.get(entryDate);
      const wakeUpTimeControl = controls.get(wakeUpTime);

      if (!entryDateControl?.value || !wakeUpTimeControl?.value) {
        return null; // Skip validation if either value is empty
      }

      const entryDateMomentValue = moment(entryDateControl.value, 'MM/DD/YYYY');

      const nextEntryDateMomentValue = moment(
        entryDateControl.value,
        'MM/DD/YYYY'
      ).add(1, 'days');

      const wakeUpTimeMomentValue = moment(
        wakeUpTimeControl.value,
        'MM/DD/YYYY'
      );

      const daysDifference = wakeUpTimeMomentValue.diff(
        entryDateMomentValue,
        'days'
      );

      if (!(daysDifference === 0 || daysDifference === 1)) {
        wakeUpTimeControl.setErrors({
          // message : Wake-up time date must either match the initially selected entry date: {{entryDate}} or be the following day: {{nextEntryDate}}
          wakeUpTimeCompEntryError: 'JOURNAL_WAKEUP_COMP_ENTRY_DATE',
          entryDate: entryDateMomentValue.format('MM/DD/YYYY'),
          nextEntryDate: nextEntryDateMomentValue.format('MM/DD/YYYY'),
        });
      } else {
        if (wakeUpTimeControl.hasError('wakeUpTimeCompEntryError')) {
          wakeUpTimeControl.setErrors(null);
        }
      }

      return null;
    };
  }

  static riseTimeShouldEarlyNow(riseFromBed: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const riseFromBedControl = controls.get(riseFromBed);

      if (!riseFromBedControl?.value) {
        return null; // Skip validation if either value is empty
      }

      const riseFromBedMomentValue = moment(
        riseFromBedControl.value,
        'MM/DD/YYYY hh:mm A'
      );

      const nowDateTime = moment(new Date(), 'MM/DD/YYYY hh:mm A');
      const hoursDifference = nowDateTime.diff(
        riseFromBedMomentValue,
        'hours',
        true
      );
      if (hoursDifference < 0) {
        riseFromBedControl.setErrors({
          // message : Rise from bed time should be earlier than the current time.
          riseTimeShouldEarlyNowError: 'JOURNAL_RISE_BED_TIME_BEFORE_NOW',
        });
      } else {
        if (riseFromBedControl.hasError('riseTimeShouldEarlyNowError')) {
          riseFromBedControl.setErrors(null);
        }
      }

      return null;
    };
  }

  static riseTimeCompEntry(
    entryDate: string,
    riseFromBed: string
  ): ValidatorFn {
    return (controls: AbstractControl) => {
      const entryDateControl = controls.get(entryDate);
      const riseFromBedControl = controls.get(riseFromBed);

      if (!entryDateControl?.value || !riseFromBedControl?.value) {
        return null; // Skip validation if either value is empty
      }

      const entryDateMomentValue = moment(entryDateControl.value, 'MM/DD/YYYY');

      const nextEntryDateMomentValue = moment(
        entryDateControl.value,
        'MM/DD/YYYY'
      ).add(1, 'days');

      const riseFromBedMomentValue = moment(
        riseFromBedControl.value,
        'MM/DD/YYYY'
      );

      const daysDifference = riseFromBedMomentValue.diff(
        entryDateMomentValue,
        'days'
      );

      if (!(daysDifference === 0 || daysDifference === 1)) {
        riseFromBedControl.setErrors({
          // message : Rise from bed time date must either match the initially selected entry date: {{entryDate}} or be the following day: {{nextEntryDate}}
          riseTimeCompEntryError: 'JOURNAL_RISE_BED_COMP_ENTRY_DATE',
          entryDate: entryDateMomentValue.format('MM/DD/YYYY'),
          nextEntryDate: nextEntryDateMomentValue.format('MM/DD/YYYY'),
        });
      } else {
        if (riseFromBedControl.hasError('riseTimeCompEntryError')) {
          riseFromBedControl.setErrors(null);
        }
      }

      return null;
    };
  }

  static dateShouldBeGreater(
    riseFromBed: string,
    wakeUpTime: string
  ): ValidatorFn {
    return (controls: AbstractControl) => {
      const riseFromBedControl = controls.get(riseFromBed);
      const wakeUpTimeControl = controls.get(wakeUpTime);

      if (!riseFromBedControl?.value || !wakeUpTimeControl?.value) {
        return null;
      }

      // Parse both times assuming 'MM/DD/YYYY hh:mm A' format
      let riseFromBedMomentValue = moment(
        riseFromBedControl.value,
        'MM/DD/YYYY hh:mm A'
      );
      let wakeUpTimeMomentValue = moment(
        wakeUpTimeControl.value,
        'MM/DD/YYYY hh:mm A'
      );

      // Ensure valid times are parsed
      if (
        !riseFromBedMomentValue.isValid() ||
        !wakeUpTimeMomentValue.isValid()
      ) {
        return null; // Skip validation if times are invalid
      }

      let hoursDifference = riseFromBedMomentValue.diff(
        wakeUpTimeMomentValue,
        'hours',
        true
      ); // Get the fractional hour difference

      if (hoursDifference < 0 || hoursDifference > 3) {
        riseFromBedControl.setErrors({
          // message : Wake-up time should be earlier than or within 3 hours of rise time.
          riseFromBedDataError: 'JOURNAL_WAKEUP_TIME_EARLIER',
        });
        wakeUpTimeControl.setErrors({
          // message : Wake-up time should be earlier than or within 3 hours of rise time.
          wakeUpTimeDataError: 'JOURNAL_WAKEUP_TIME_EARLIER',
        });
      } else {
        if (riseFromBedControl.hasError('riseFromBedDataError')) {
          riseFromBedControl.setErrors(null);
        }
        if (wakeUpTimeControl.hasError('wakeUpTimeDataError')) {
          wakeUpTimeControl.setErrors(null);
        }
      }

      return null;
    };
  }

  static bedTimeShouldEarlyNow(goToBed: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const goToBedControl = controls.get(goToBed);

      if (!goToBedControl?.value) {
        return null; // Skip validation if either value is empty
      }

      const goToBedMomentValue = moment(
        goToBedControl.value,
        'MM/DD/YYYY hh:mm A'
      );

      const nowDateTime = moment(new Date(), 'MM/DD/YYYY hh:mm A');
      let hoursDifference = nowDateTime.diff(goToBedMomentValue, 'hours', true);
      if (hoursDifference < 0) {
        goToBedControl.setErrors({
          // Go to bed time should be earlier than the current time.
          bedTimeShouldEarlyNowError: 'JOURNAL_BED_TIME_BEFORE_NOW',
        });
      } else {
        if (goToBedControl.hasError('bedTimeShouldEarlyNowError')) {
          goToBedControl.setErrors(null);
        }
      }

      return null;
    };
  }

  static bedTimeCompEntry(entryDate: string, goToBed: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const entryDateControl = controls.get(entryDate);
      const goToBedControl = controls.get(goToBed);

      if (!entryDateControl?.value || !goToBedControl?.value) {
        return null; // Skip validation if either value is empty
      }

      const entryDateTimeMomentValue = moment(
        entryDateControl.value,
        'MM/DD/YYYY'
      ).startOf('day');

      const nextEntryDateTimeMomentValue = moment(
        entryDateControl.value,
        'MM/DD/YYYY'
      )
        .add(1, 'days')
        .set({
          hour: 6,
          minute: 0,
          second: 0,
        });

      const goToBedMomentValue = moment(
        goToBedControl.value,
        'M/D/YYYY h:mm A'
      );

      if (
        !(
          goToBedMomentValue.isSameOrAfter(entryDateTimeMomentValue) &&
          goToBedMomentValue.isBefore(nextEntryDateTimeMomentValue)
        )
      ) {
        goToBedControl.setErrors({
          // message : Go to bed time date must either match the initially selected entry date: {{entryDate}} or be the following day: {{nextEntryDate}}
          bedTimeCompEntryError: 'JOURNAL_BED_COMP_ENTRY_DATE',
          entryDate: entryDateTimeMomentValue.format('M/D/YYYY'),
          nextEntryDate: nextEntryDateTimeMomentValue.format('M/D/YYYY'),
        });
      } else if (goToBedControl.hasError('bedTimeCompEntryError')) {
        goToBedControl.setErrors(null);
      }
      return null;
    };
  }
  // if (
  //   !(
  //     daysDifference === 0 ||
  //     (daysDifference === 1 && hoursDifference > 0)
  //   )
  // ) {
  //   // const daysDifference = goToBedMomentValue.diff(
  //   entryDateMomentValue,
  //   'days'
  // );

  // const nextEntryDateTimeMomentValue = nextEntryDateMomentValue.set({
  //   hour: 6,
  //   minute: 0,
  //   second: 0,
  // });
  // const goToBedDateTimeMomentValue = moment(
  //   goToBedControl.value,
  //   'MM/DD/YYYY HH:mm'
  // );

  // const hoursDifference = nextEntryDateTimeMomentValue.diff(
  //   goToBedDateTimeMomentValue,
  //   'hours',
  //   true
  // );

  // console.log('nextEntryDateTimeMomentValue', nextEntryDateTimeMomentValue);
  // console.log('goToBedDateTimeMomentValue', goToBedDateTimeMomentValue);
  // console.log('hoursDifference', hoursDifference);

  //         goToBedControl.setErrors({
  //           // message : Go to bed time date must either match the initially selected entry date: {{entryDate}} or be the following day: {{nextEntryDate}}
  //           bedTimeCompEntryError: 'JOURNAL_BED_COMP_ENTRY_DATE',
  //           entryDate: entryDateMomentValue.format('MM/DD/YYYY'),
  //           nextEntryDate: nextEntryDateMomentValue.format('MM/DD/YYYY'),
  //         });
  //       } else {
  //         if (goToBedControl.hasError('bedTimeCompEntryError')) {
  //           goToBedControl.setErrors(null);
  //         }
  //       }

  //     return null;
  //   };
  // }

  static lightsOffTimeShouldEarlyNow(lightsOffTime: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const lightsOffTimeControl = controls.get(lightsOffTime);

      if (!lightsOffTimeControl?.value) {
        return null; // Skip validation if either value is empty
      }

      const ightsOffTimeMomentValue = moment(
        lightsOffTimeControl.value,
        'MM/DD/YYYY hh:mm A'
      );

      const nowDateTime = moment(new Date(), 'MM/DD/YYYY hh:mm A');
      let hoursDifference = nowDateTime.diff(
        ightsOffTimeMomentValue,
        'hours',
        true
      );
      if (hoursDifference < 0) {
        lightsOffTimeControl.setErrors({
          // Lights off time should be earlier than the current time.
          lightsOffTimeShouldEarlyNowError:
            'JOURNAL_LIGHTS_OFF_TIME_BEFORE_NOW',
        });
      } else {
        if (lightsOffTimeControl.hasError('lightsOffTimeShouldEarlyNowError')) {
          lightsOffTimeControl.setErrors(null);
        }
      }

      return null;
    };
  }

  static lightsOffTimeCompEntry(
    entryDate: string,
    lightsOffTime: string
  ): ValidatorFn {
    return (controls: AbstractControl) => {
      const entryDateControl = controls.get(entryDate);
      const lightsOffTimeControl = controls.get(lightsOffTime);

      if (!entryDateControl?.value || !lightsOffTimeControl?.value) {
        return null; // Skip validation if either value is empty
      }

      const entryDateMomentValue = moment(entryDateControl.value, 'MM/DD/YYYY');

      const nextEntryDateMomentValue = moment(
        entryDateControl.value,
        'MM/DD/YYYY'
      ).add(1, 'days');

      const lightsOffTimeMomentValue = moment(
        lightsOffTimeControl.value,
        'MM/DD/YYYY'
      );

      const daysDifference = lightsOffTimeMomentValue.diff(
        entryDateMomentValue,
        'days'
      );

      if (!(daysDifference === 0 || daysDifference === 1)) {
        lightsOffTimeControl.setErrors({
          // message : Lights off time date must either match the initially selected entry date: {{entryDate}} or be the following day: {{nextEntryDate}}
          lightsOffTimeCompEntryError: 'JOURNAL_LIGHTS_OFF_COMP_ENTRY_DATE',
          entryDate: entryDateMomentValue.format('MM/DD/YYYY'),
          nextEntryDate: nextEntryDateMomentValue.format('MM/DD/YYYY'),
        });
      } else {
        if (lightsOffTimeControl.hasError('lightsOffTimeCompEntryError')) {
          lightsOffTimeControl.setErrors(null);
        }
      }

      return null;
    };
  }

  static dateShouldBeLessThan(lightsOff: string, goToBed: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const lightsOffControl = controls.get(lightsOff);
      const goToBedControl = controls.get(goToBed);

      if (!lightsOffControl?.value || !goToBedControl?.value) {
        return null;
      }

      // Parse both times assuming 'MM/DD/YYYY hh:mm A' format
      let lightsOffMomentValue = moment(
        lightsOffControl.value,
        'MM/DD/YYYY hh:mm A'
      );
      let goToBedMomentValue = moment(
        goToBedControl.value,
        'MM/DD/YYYY hh:mm A'
      );

      // Ensure valid times are parsed
      if (!lightsOffMomentValue.isValid() || !goToBedMomentValue.isValid()) {
        return null; // Skip validation if times are invalid
      }

      let hoursDifference = lightsOffMomentValue.diff(
        goToBedMomentValue,
        'hours',
        true
      ); // Get the fractional hour difference

      if (hoursDifference < 0 || hoursDifference > 3) {
        // Message :Bedtime should be earlier than or within 3 hours of lights-off time.
        lightsOffControl.setErrors({
          lightsOffTimeDataError: 'JOURNAL_BED_TIME_EARLIER',
        });
        goToBedControl.setErrors({
          goToBedTimeDataError: 'JOURNAL_BED_TIME_EARLIER',
        });
      } else {
        if (lightsOffControl.hasError('lightsOffTimeDataError')) {
          lightsOffControl.setErrors(null);
        }
        if (goToBedControl.hasError('goToBedTimeDataError')) {
          goToBedControl.setErrors(null);
        }
      }

      return null;
    };
  }

  static checkBedTime(riseFromBed: string, goToBed: string) {
    return (controls: AbstractControl) => {
      const goToBedControl = controls.get(goToBed);
      const riseFromBedControl = controls.get(riseFromBed);

      if (!goToBedControl?.value || !riseFromBedControl?.value) {
        return null;
      }

      let goToBedMomentValue = moment(
        goToBedControl.value,
        'MM/DD/YYYY hh:mm A'
      );
      let riseFromBedMomentValue = moment(
        riseFromBedControl.value,
        'MM/DD/YYYY hh:mm A'
      );

      if (!goToBedMomentValue.isValid() || !riseFromBedMomentValue.isValid()) {
        return null;
      }

      let hoursDifference = riseFromBedMomentValue.diff(
        goToBedMomentValue,
        'hours',
        true
      );

      if (hoursDifference > 18 || hoursDifference < 0) {
        // Message: Go to bed time should be earlier than or within 18 hours of the rise from bed time.
        goToBedControl.setErrors({
          bedTimeError: 'JOURNAL_BED_TIME_LESS_18',
        });
        riseFromBedControl.setErrors({
          bedTimeError: 'JOURNAL_BED_TIME_LESS_18',
        });
      } else {
        if (goToBedControl.hasError('bedTimeError')) {
          goToBedControl.setErrors(null);
        }
        if (riseFromBedControl.hasError('bedTimeError')) {
          riseFromBedControl.setErrors(null);
        }
      }

      return null;
    };
  }

  static checkBedTimeGreaterThanYesterday(
    entryDate: string,
    goToBed: string,
    courseService: CourseService
  ) {
    return (controls: AbstractControl) => {
      const goToBedControl = controls.get(goToBed);
      const entryDateControl = controls.get(entryDate);

      if (!goToBedControl?.value || !entryDateControl?.value) {
        return null;
      }

      let goToBedMomentValue = moment(
        goToBedControl.value,
        'MM/DD/YYYY hh:mm A'
      );

      if (!goToBedMomentValue.isValid()) {
        return null;
      }

      let lastEntryDateMomentValue = moment(entryDateControl.value).add(
        -1,
        'days'
      );

      courseService
        .getSleepJournal(lastEntryDateMomentValue.toDate())
        .subscribe((data) => {
          if (data && Object.keys(data).length > 0) {
            const riseFromBedTime = data.rise_from_bed_date_time;
            let riseFromBedMomentValue = moment(riseFromBedTime);
            const hoursDifference = goToBedMomentValue.diff(
              riseFromBedMomentValue,
              'hours',
              true
            );

            if (hoursDifference < 4) {
              // The rise-from-bed time recorded for yesterday was <b>{{ riseFromBedTime }}</b>. Your go-to-bed time should be set at least 4 hours after <b>{{ riseFromBedTime }}</b>.
              goToBedControl.setErrors({
                checkBedTimeGreaterThanYesterdayError:
                  'JOURNAL_BED_TIME_AFTER_YESTERDAY',
                riseFromBedTime:
                  riseFromBedMomentValue.format('MM/DD/YYYY hh:mm A'),
              });
            } else {
              if (
                goToBedControl.hasError('checkBedTimeGreaterThanYesterdayError')
              ) {
                goToBedControl.setErrors(null);
              }
            }
          }
        });
      return null;
    };
  }

  static valueShouldNotBeEmpty(numberawakening: string, minutesawake: string) {
    return (controls: AbstractControl) => {
      const numberawakeningControl = controls.get(numberawakening);
      const minutesawakeControl = controls.get(minutesawake);

      if (
        numberawakeningControl.value == 0 &&
        minutesawakeControl.value != 0 &&
        minutesawakeControl.value != null
      ) {
        // Message: Number of awakening must not be equal to zero.
        numberawakeningControl.setErrors({
          valueShouldNotBeEmptyError: 'JOURNAL_RECORD_NO_AWAKE',
        });
      } else {
        if (numberawakeningControl.hasError('valueShouldNotBeEmptyError')) {
          numberawakeningControl.setErrors(null);
        }
      }

      if (
        numberawakeningControl.value != 0 &&
        numberawakeningControl.value != null &&
        minutesawakeControl.value == 0
      ) {
        // Message: Minutes awake must not be equal to zero.
        minutesawakeControl.setErrors({
          valueShouldNotBeEmptyError: 'JOURNAL_RECORD_TIME_AWAKE',
        });
      } else {
        if (minutesawakeControl.hasError('valueShouldNotBeEmptyError')) {
          minutesawakeControl.setErrors(null);
        }
      }

      return null;
    };
  }

  static lightsOffEarlyWake(wakeUpTime: string, turnLightsOff: string) {
    return (controls: AbstractControl) => {
      const turnLightsOffControl = controls.get(turnLightsOff);
      const wakeUpTimeControl = controls.get(wakeUpTime);

      if (!wakeUpTimeControl?.value || !turnLightsOffControl?.value) {
        return null; // Skip validation if either value is empty
      }

      // Parse both times assuming 'MM/DD/YYYY hh:mm A' format
      let weakUpTimeMomentValue = moment(
        wakeUpTimeControl.value,
        'MM/DD/YYYY hh:mm A'
      );
      let turnLightsOffMomentValue = moment(
        turnLightsOffControl.value,
        'MM/DD/YYYY hh:mm A'
      );

      // Ensure valid times are parsed
      if (
        !weakUpTimeMomentValue.isValid() ||
        !turnLightsOffMomentValue.isValid()
      ) {
        return null; // Skip validation if times are invalid
      }

      let hoursDifference = weakUpTimeMomentValue.diff(
        turnLightsOffMomentValue,
        'hours',
        true
      );
      if (hoursDifference < 0) {
        wakeUpTimeControl.setErrors({
          sleepTimeNegativeError: 'JOURNAL_SLEEP_TIME_NEGATIVE',
        });
        turnLightsOffControl.setErrors({
          sleepTimeNegativeError: 'JOURNAL_SLEEP_TIME_NEGATIVE',
        });
      } else {
        if (wakeUpTimeControl.hasError('sleepTimeNegativeError')) {
          wakeUpTimeControl.setErrors(null);
        }
        if (turnLightsOffControl.hasError('sleepTimeNegativeError')) {
          turnLightsOffControl.setErrors(null);
        }
      }

      return null;
    };
  }

  static checkSleepTime(
    wakeUpTime: string,
    turnLightsOff: string,
    durationFallingSleep: string,
    minutesAwake: string
  ) {
    return (controls: AbstractControl) => {
      const turnLightsOffControl = controls.get(turnLightsOff);
      const wakeUpTimeControl = controls.get(wakeUpTime);
      const durationFallingSleepControl = controls.get(durationFallingSleep);
      const minutesAwakeControl = controls.get(minutesAwake);

      if (
        !wakeUpTimeControl?.value ||
        !turnLightsOffControl?.value ||
        !durationFallingSleepControl?.value ||
        !minutesAwakeControl?.value
      ) {
        return null; // Skip validation if either value is empty
      }

      // Parse both times assuming 'MM/DD/YYYY hh:mm A' format
      let weakUpTimeMomentValue = moment(
        wakeUpTimeControl.value,
        'MM/DD/YYYY hh:mm A'
      );
      let turnLightsOffMomentValue = moment(
        turnLightsOffControl.value,
        'MM/DD/YYYY hh:mm A'
      );

      // Ensure valid times are parsed
      if (
        !weakUpTimeMomentValue.isValid() ||
        !turnLightsOffMomentValue.isValid()
      ) {
        return null; // Skip validation if times are invalid
      }

      let hoursDifference = weakUpTimeMomentValue.diff(
        turnLightsOffMomentValue,
        'hours',
        true
      );

      let durationFallingSleepInHours = 0;
      let minutesAwakeInHours = 0;
      if (
        !isNaN(durationFallingSleepControl.value) &&
        durationFallingSleepControl.value > 0
      ) {
        durationFallingSleepInHours =
          parseFloat(durationFallingSleepControl.value) / 60;
      }
      if (!isNaN(minutesAwakeControl.value) && minutesAwakeControl.value > 0) {
        minutesAwakeInHours = parseFloat(minutesAwakeControl.value) / 60;
      }
      hoursDifference -= durationFallingSleepInHours + minutesAwakeInHours;

      if (hoursDifference > 14) {
        wakeUpTimeControl.setErrors({
          sleepTimeCheckError: 'JOURNAL_SLEEP_TIME_GREATER_14',
        });
        turnLightsOffControl.setErrors({
          sleepTimeCheckError: 'JOURNAL_SLEEP_TIME_GREATER_14',
        });
        durationFallingSleepControl.setErrors({
          sleepTimeCheckError: 'JOURNAL_SLEEP_TIME_GREATER_14',
        });
        minutesAwakeControl.setErrors({
          sleepTimeCheckError: 'JOURNAL_SLEEP_TIME_GREATER_14',
        });
      } else {
        if (wakeUpTimeControl.hasError('sleepTimeCheckError')) {
          wakeUpTimeControl.setErrors(null);
        }
        if (turnLightsOffControl.hasError('sleepTimeCheckError')) {
          turnLightsOffControl.setErrors(null);
        }
        if (durationFallingSleepControl.hasError('sleepTimeCheckError')) {
          durationFallingSleepControl.setErrors(null);
        }
        if (minutesAwakeControl.hasError('sleepTimeCheckError')) {
          minutesAwakeControl.setErrors(null);
        }
      }
      return null;
    };
  }
}
