import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MyService } from '../filter-service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.less'],
})
export class FilterComponent implements OnInit {
  filter: string = 'daily';
  currentDate: Date = new Date();
  crtValue: string;
  sharedVar;
  savedLang;
  constructor(
    private myService: MyService,
    private translate: TranslateService
  ) {
    this.filter = this.myService.crtValue;
  }
  ngOnInit(): void {
    if (localStorage.getItem('selectedLanguage')) {
      this.savedLang = localStorage.getItem('selectedLanguage');
    } else {
      this.savedLang = this.translate.defaultLang;
    }
    this.translate.use(this.savedLang);

    this.translate.onLangChange.subscribe((event) => {
      localStorage.setItem('selectedLanguage', event.lang);
      this.savedLang = event.lang;
    });
  }
  change() {
    this.myService.setValue(this.filter);
  }
}
