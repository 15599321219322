import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { CourseService } from '@app/_services/course.service';
import { CompleteDialogComponent } from '@app/dialog/complete-dialog/complete-dialog.component';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-week5-page6',
  templateUrl: './week5-page6.component.html',
  styleUrls: ['./week5-page6.style.scss'],
})
export class Week5Page6Component implements OnInit {
  form: FormGroup;
  formError = false;
  doctorSuggestion: string = '';

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '100px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    placeholder: 'Enter text here ...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'heading',
        'fontName',
        'backgroundColor',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'insertImage',
        'insertVideo',
        'insertLink',
        'toggleEditorMode',
      ],
    ],
  };

  constructor(
    private router: Router,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private courseService: CourseService,
    private fb: FormBuilder,
    private alertService: AlertService,
    private dialog: MatDialog
  ) {
    this.form = this.fb.group({
      week_number: ['6'],
      self_assessment_text: ['', Validators.required],
      reflection_text: ['', Validators.required],
      doctor_suggestion: [''],
    });
  }

  ngOnInit(): void {
    const savedLang = localStorage.getItem('selectedLanguage');
    if (savedLang) {
      this.translate.use(savedLang);
    } else {
      this.translate.use(this.translate.defaultLang);
    }

    this.courseService.isStudied(6, 1).then((result) => {
      if (result) {
        this.courseService.getSelfAssessmentReflection(6).subscribe(
          (data) => {
            if (data) {
              this.form.patchValue({
                self_assessment_text: data.self_assessment_text,
                reflection_text: data.reflection_text,
                doctor_suggestion: data.doctor_suggestion,
              });
              this.doctorSuggestion = data?.doctor_suggestion;
            }
          },
          (error) => {
            console.error(
              'Error fetching self-assessment and reflection data.',
              error
            );
          }
        );
      }
    });

    this.translate.onLangChange.subscribe((event) => {
      localStorage.setItem('selectedLanguage', event.lang);
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.courseService.saveSelfAssessmentReflection(this.form.value).subscribe(
      (response) => {
        this.courseService.updateProgress(6, 2);
        const dialogRef = this.dialog.open(CompleteDialogComponent, {
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result === 'journal') {
            this.router.navigate(['/journal']);
          }
          if (result === 'progress') {
            this.router.navigate(['/diary']);
          }
        });
      },
      (error) => {
        this.alertService.error(
          'Self-Assessment and reflection saved failure. Please try again later!'
        );
      }
    );
  }
}
