import { Component, OnInit } from '@angular/core';
import { AlertService } from '@app/_services';
import { PatientService } from '@app/_services/patient.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { CourseService } from '@app/_services/course.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HttpClient } from '@angular/common/http';
import { EmailVerificationService } from '@app/_services/email-verification.service';

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.style.scss'],
})
export class CourseDetailsComponent implements OnInit {
  patientId: number;
  // detailsData: Object;
  // patientDetails: Object;
  courseDetails: any;
  profileData: any;
  progressData: any;
  selfReflectData: Object;
  sleepJournalData: Object;
  desiredSleepData: Object;

  showComponent = true;
  form: FormGroup;
  suggestion1: string = '';
  suggestion2: string = '';
  suggestion3: string = '';
  suggestion4: string = '';
  suggestion5: string = '';

  finalSuggestion: string = '';
  message: string = '';
  isSending: boolean = false;

  constructor(
    public patientService: PatientService,
    private activatedRoute: ActivatedRoute,
    private courseService: CourseService,
    private emailService: EmailVerificationService
  ) {}

  ngOnInit(): void {
    const userId = this.activatedRoute.snapshot.paramMap.get('id');

    this.patientId = Number(userId);
    if (userId) {
      this.patientService.getPatientCourseDetailsById(this.patientId).subscribe(
        (courseDetails) => {
          if (courseDetails) {
            this.courseDetails = courseDetails;
            console.log(this.courseDetails);

            if (courseDetails?.profile) {
              this.profileData = courseDetails.profile;
            }

            if (courseDetails?.progress) {
              this.progressData = courseDetails.progress;
            }

            if (courseDetails?.reflectinfo) {
              this.selfReflectData = courseDetails.reflectinfo;
              this.suggestion1 = this.selfReflectData[0]?.doctor_suggestion;
              this.suggestion2 = this.selfReflectData[1]?.doctor_suggestion;
              this.suggestion3 = this.selfReflectData[2]?.doctor_suggestion;
              this.suggestion4 = this.selfReflectData[3]?.doctor_suggestion;
              this.suggestion5 = this.selfReflectData[4]?.doctor_suggestion;
              this.finalSuggestion = this.selfReflectData[5]?.doctor_suggestion;
            }

            if (courseDetails?.journalinfo) {
              this.sleepJournalData = courseDetails.journalinfo;
            }

            if (courseDetails?.desiredinfo) {
              this.desiredSleepData = courseDetails.desiredinfo;
            }
          }
        },
        (error) => {
          console.error('Error fetching course details data.', error);
        }
      );
    }
  }

  getDailySleepTime(totalSleepTime): string {
    const totalSleepInMinutes = Number(totalSleepTime);

    const dailyHours = Math.floor(totalSleepInMinutes / 7 / 60);
    const dailyMinutes = Math.round((totalSleepInMinutes / 7) % 60);

    return dailyMinutes === 0
      ? `${dailyHours}h`
      : dailyHours === 0
      ? `${dailyMinutes}min`
      : `${dailyHours}h ${dailyMinutes}min`;
  }

  getDesiredSleepTime(totalSleepTime): string {
    const totalSleepInMinutes = Number(totalSleepTime);

    const dailyHours = Math.floor(totalSleepInMinutes / 60);
    const dailyMinutes = Math.round(totalSleepInMinutes % 60);

    return dailyMinutes === 0
      ? `${dailyHours}h`
      : dailyHours === 0
      ? `${dailyMinutes}min`
      : `${dailyHours}h ${dailyMinutes}min`;
  }

  parseMomentDate(modifiedDate: string) {
    if (modifiedDate == null) {
      return '-';
    }

    try {
      return moment(modifiedDate).format('M-D-YYYY');
    } catch (error) {
      return '-';
    }
  }

  parseMomentDateTime(modifiedDate: string) {
    if (modifiedDate == null) {
      return '-';
    }

    try {
      return moment(modifiedDate).format('M-D-YYYY h:mm A');
    } catch (error) {
      return '-';
    }
  }

  formatTimeWithMoment(time: string): string {
    if (!time) return '-';

    const timeString = `1970-01-01T${time}`;
    return moment(timeString).format('h:mm A');
  }

  parseMomentStartYM(modifiedDate: string) {
    if (modifiedDate == null) {
      return '';
    }

    try {
      return `${moment(modifiedDate).format('M-D')} -`;
    } catch (error) {
      return '';
    }
  }

  parseMomentEndYM(modifiedDate: string) {
    if (modifiedDate == null) {
      return '';
    }

    try {
      return moment(modifiedDate).format('M-D');
    } catch (error) {
      return '';
    }
  }

  addDayAndFormat(dateString: string, dayNumber: number): string {
    if (dateString == null) {
      return '';
    }

    try {
      if (dayNumber === 1) {
        return `${moment(dateString).add(dayNumber, 'day').format('M-D')} -`;
      }
      return moment(dateString).add(dayNumber, 'day').format('M-D');
    } catch (error) {
      return '';
    }
  }

  saveSuggestion(week_number: number, suggestion: string) {
    const data = {
      week_number: week_number,
      suggestion: suggestion || '',
      id: this.patientId,
    };

    this.courseService.updateDoctorSuggestion(data).subscribe();
  }
  // }

  sendSuggestion() {
    const newReflectData = [
      { week_number: 1, doctor_suggestion: this.suggestion1 },
      { week_number: 2, doctor_suggestion: this.suggestion2 },
      { week_number: 3, doctor_suggestion: this.suggestion3 },
      { week_number: 4, doctor_suggestion: this.suggestion4 },
      { week_number: 5, doctor_suggestion: this.suggestion5 },
      { week_number: 6, doctor_suggestion: this.finalSuggestion },
    ].filter((item) => item.doctor_suggestion);
    const data = {
      first_name: this.courseDetails.first_name,
      last_name: this.courseDetails.last_name,
      email: this.courseDetails.email,
      reflect_info: newReflectData,
    };
    this.emailService.sendSuggestionNotificattion(data).subscribe({
      next: () => {
        this.message = 'Your email has been sent successfully!';
      },
      error: (err) => {
        this.message = `Error sending code: ${
          err.error.detail || 'Unexpected error occurred.'
        }`;
      },
    });
  }
}
