import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CourseService } from '@app/_services/course.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-week1-page3',
  templateUrl: 'week1-page3.component.html',
  styleUrls: ['week1-page3.style.scss'],
})
export class Week1Page3Component implements OnInit {
  videoSrc: string = '';
  isVideoEnded: boolean = false;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private courseService: CourseService
  ) {}

  ngOnInit(): void {
    const savedLang = localStorage.getItem('selectedLanguage');
    if (savedLang) {
      this.translate.use(savedLang);
    } else {
      this.translate.use(this.translate.defaultLang);
    }

    this.courseService.isStudied(1, 3).then((result) => {
      if (result) {
        this.isVideoEnded = true;
      } else {
        const isVideoWatched =
          localStorage.getItem('isW1P3VideoWatched') === 'true';
        this.isVideoEnded = isVideoWatched;
      }
    });
    this.setVideoSource();

    this.translate.onLangChange.subscribe((event) => {
      localStorage.setItem('selectedLanguage', event.lang);
      this.setVideoSource();
    });
  }

  setVideoSource(): void {
    const currentLang = this.translate.currentLang;

    if (currentLang === 'sch' || currentLang === 'tch') {
      this.videoSrc = '/assets/videos/week1_3_take_action_ch.mp4';
    } else {
      this.videoSrc = '/assets/videos/week1_3_take_action_en.mp4';
    }
    this.cdr.detectChanges();
  }

  onVideoEnded(): void {
    this.isVideoEnded = true;
    localStorage.setItem('isW1P3VideoWatched', 'true');
  }

  onNextClick() {
    this.courseService.unlockWeek(0);
    this.courseService.unlockWeek1Content(3);
    this.courseService.updateProgress(1, 4);

    this.router.navigate(['week1/self_assessment_and_reflection']); // navigate to intropage2
  }
}
