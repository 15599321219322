import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { Week2Component } from './week2.component';
import { Week2Page1Component } from './week2-page1/week2-page1.component';
import { Week2Page2Component } from './week2-page2/week2-page2.component';
import { Week2Page3Component } from './week2-page3/week2-page3.component';
import { Week2Page4Component } from './week2-page4/week2-page4.component';
import { Week2Page5Component } from './week2-page5/week2-page5.component';
import { Week2Page6Component } from './week2-page6/week2-page6.component';
import { Week2Page7Component } from './week2-page7/week2-page7.component';
import { Week2Page8Component } from './week2-page8/week2-page8.component';

import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AngularEditorModule,
    RouterModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    Week2Component,
    Week2Page1Component,
    Week2Page2Component,
    Week2Page3Component,
    Week2Page4Component,
    Week2Page5Component,
    Week2Page6Component,
    Week2Page7Component,
    Week2Page8Component,
  ],
  exports: [Week2Page2Component],
})
export class Week2Module {}
