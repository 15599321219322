<div class="desktop">
  <div class="custom-dialog">
    <h2>
      <!-- Course Access Reminder -->
      {{ "DIALOG_COURSE_ACCESS_REMINDER" | translate }}
    </h2>

    <h3>
      <p>
        {{ "DIALOG_LONG_TIME_REMINDER" | translate }}
      </p>
      <div [innerHTML]="safeHtmlContentContinue"></div>
      <div [innerHTML]="safeHtmlContentReset"></div>
    </h3>
    <div class="form-group text-center">
      <button
        class="btn btn-primary field mx-auto col-3 me-2"
        (click)="onContinue()"
        style="min-width: 160px"
      >
        <span>{{ "DIALOG_CONTINUE" | translate }}</span
        ><br /><span>{{ "DIALOG_KEEP_DATA" | translate }}</span>
      </button>
      <button
        class="btn btn-primary field mx-auto col-3 ms-2"
        (click)="onReset()"
        style="min-width: 180px"
      >
        <span>{{ "DIALOG_RESET" | translate }}</span
        ><br />
        <span>{{ "DIALOG_RESTART_COURSE" | translate }}</span>
      </button>
    </div>
  </div>
</div>
<div class="mobile">
  <div class="custom-dialog-mobile">
    <h2>
      <!-- Course Access Reminder -->
      {{ "DIALOG_COURSE_ACCESS_REMINDER" | translate }}
    </h2>

    <h3>
      <p>
        {{ "DIALOG_LONG_TIME_REMINDER" | translate }}
      </p>
      <div [innerHTML]="safeHtmlContentContinue"></div>
      <div [innerHTML]="safeHtmlContentReset"></div>
    </h3>
    <div class="form-group text-center">
      <button
        class="btn btn-primary field mx-auto col-3 me-2"
        (click)="onContinue()"
        style="min-width: 180px"
      >
        <span>{{ "DIALOG_CONTINUE" | translate }}</span
        ><br /><span>{{ "DIALOG_KEEP_DATA" | translate }}</span>
      </button>
      <br /><br />
      <button
        class="btn btn-primary field mx-auto col-3 ms-2"
        (click)="onReset()"
        style="min-width: 180px"
      >
        <span>{{ "DIALOG_RESET" | translate }}</span
        ><br />
        <span>{{ "DIALOG_RESTART_COURSE" | translate }}</span>
      </button>
    </div>
  </div>
</div>
