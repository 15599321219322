<div class="add-patient container p-4">
  <h1 class="pb-4 pt-4">Add Patient</h1>

  <div class="register-form">
    <form class="p-4" [formGroup]="form" (ngSubmit)="onSubmit()">
      <h4 style="color: #002e46; font-size: 18px">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label>First Name</label>
              <input
                type="text"
                formControlName="firstName"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
              />
              <div
                *ngIf="submitted && f.firstName.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.firstName.errors.required">Field is required</div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label>Last Name</label>
              <input
                type="text"
                formControlName="lastName"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"
              />
              <div
                *ngIf="submitted && f.lastName.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.lastName.errors.required">Field is required</div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label>Sex</label>
              <select
                formControlName="sex"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.sex.errors }"
              >
                <option>Male</option>
                <option>Female</option>
              </select>
              <div *ngIf="submitted && f.sex.errors" class="invalid-feedback">
                <div *ngIf="f.sex.errors.required">Field is required</div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label>Date of Birth</label>
              <input
                type="date"
                max="202-04-24"
                formControlName="dob"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.dob.errors }"
              />
              <div *ngIf="submitted && f.dob.errors" class="invalid-feedback">
                <div *ngIf="f.dob.errors.required">Field is required</div>
              </div>
              <div *ngIf="submitted && f.dob.errors" class="invalid-feedback">
                <div *ngIf="f.dob.errors.dateLessThan">
                  Date of Birth should be less than today
                </div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label>Email Address</label>
              <input
                type="email"
                formControlName="email"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
              />
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Field is required</div>
              </div>
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.email">
                  Please enter valid email address
                </div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label>Mobile</label>
              <input
                type="text"
                formControlName="mobile"
                class="form-control field"
                [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"
              />
              <div
                *ngIf="submitted && f.mobile.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.mobile.errors.required">Field is required</div>
              </div>
              <div
                *ngIf="submitted && f.mobile.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.mobile.errors.dateLessThan">
                  Date of Birth should be less than today
                </div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label>Password</label>
              <input
                type="password"
                formControlName="password"
                class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
              />
              <div
                *ngIf="submitted && f.password.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.password.errors.required">
                  Password is required
                </div>
                <div *ngIf="f.password.errors.minlength">
                  Password must be at least 6 characters
                </div>
                <div *ngIf="f.password.errors.maxlength">
                  lastName must not exceed 40 characters
                </div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label>Confirm Password</label>
              <input
                type="password"
                formControlName="confirmPassword"
                class="form-control"
                [ngClass]="{
                  'is-invalid': submitted && f.confirmPassword.errors
                }"
              />
              <div
                *ngIf="submitted && f.confirmPassword.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.confirmPassword.errors.required">
                  Confirm Password is required
                </div>
                <div *ngIf="f.confirmPassword.errors.matching">
                  Confirm Password does not match
                </div>
              </div>
            </div>
          </div>

          <div class="col-6"></div>
        </div>

        <div class="col-6"></div>

        <div class="form-group form-action-container">
          <button type="submit" class="btn btn-primary rounded-pill w-25 my-2">
            Create
          </button>
          <button
            type="button"
            (click)="onReset()"
            class="btn btn-secondary rounded-pill w-25 my-2 reset-button"
          >
            Reset Form
          </button>
        </div>
      </h4>
    </form>
  </div>
</div>
