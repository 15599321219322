<svg width="100%" height="100%" viewBox="0 0 42 42" class="donut">
  <circle
    class="donut-hole"
    cx="21"
    cy="21"
    r="15.91549430918954"
    fill="#fff"
  ></circle>
  <!-- <rect x="0" y=  "0" fill="#red" width="1000" height="500">
    <svg:animateTransform attributeName="transform" type="rotate" begin="0s" dur="30s" fill="freeze" from="0,500,500" to="180,500,500"/>
  </rect> -->
  <circle
    class="donut-ring"
    cx="21"
    cy="21"
    r="15.91549430918954"
    fill="transparent"
    stroke="#94c4dc"
    stroke-width="3"
  ></circle>
  <!-- <rect x="0" y=  "0" fill="#fff" width="1000" height="500">
    <svg:animateTransform attributeName="transform" type="rotate" begin="0s" dur="30s" fill="freeze" from="0,500,500" to="180,500,500"/>
  </rect> -->
  <!--Ref: https://stackoverflow.com/questions/28128491/svg-center-text-in-circle -->
  <text
    class="animated-digit"
    x="50%"
    y="50%"
    text-anchor="middle"
    stroke="#51c5cf"
    stroke-width="0px"
    dy=".3em"
    style="stroke-size: 0; font-size: 0.7rem"
    [style.font-size]="dailyLabel ? '2px' : '0.7rem'"
  >
    <tspan *ngIf="!dailyLabel" style="font-size: 8px">
      {{ !value ? firstValue : value }}
    </tspan>
    <tspan *ngIf="!dailyLabel" style="font-size: 8px">%</tspan>
    <tspan *ngIf="!dailyLabel && down" style="font-size: 4px" fill="#FD8415">
      &#8595;
    </tspan>
    <tspan *ngIf="!dailyLabel && up" style="font-size: 4px" fill="#15fdb0">
      &#8593;
    </tspan>
    <!-- <tspan *ngIf="!dailyLabel" x="50%" dy="1.3em" style="font-size:2px;" fill="#055D9D">Sleep Efficiency</tspan> -->
    <tspan *ngIf="dailyLabel" x="50%" dy="0.3em" fill="#055D9D">
      <tspan fill="#94c4dc">&#11044;</tspan>
      {{ "DIARY_TOTAL_TIME_BED" | translate }}
    </tspan>
    <tspan *ngIf="dailyLabel" x="49%" dy="1.3em" fill="#055D9D">
      <tspan fill="#002642">&#11044;</tspan>
      {{ "DIARY_TOTAL_SLEEP_TIME" | translate }}
    </tspan>
  </text>
  <circle
    class="donut-segment"
    cx="21"
    cy="21"
    r="15.91549430918954"
    fill="transparent"
    stroke="#002642"
    stroke-width="3"
    stroke-width="3"
    stroke-linecap="round"
    [attr.stroke-dasharray]="arr.join(' ')"
    stroke-dashoffset="0"
  ></circle>
  <!-- <rect x="0" y=  "0" fill="#fff" width="1000" height="500">
    <animateTransform attributeName="transform"
                          attributeType="XML"
                          type="rotate"
                          from="0 60 70"
                          to="360 60 70"
                          dur="10s"
                          repeatCount="indefinite"/>
                          </rect> -->
  <!-- <rect x="0" y=  "0" fill="#fff" width="1000" height="500">
      <svg:animateTransform attributeName="transform" type="rotate" begin="0s" dur="30s" fill="freeze" from="0,500,500" to="180,500,500"/>
    </rect> -->
</svg>
