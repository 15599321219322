import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-complete-dialog',
  templateUrl: './complete-dialog.component.html',
  styleUrls: ['./complete-dialog.style.scss'],
})
export class CompleteDialogComponent {
  constructor(public dialogRef: MatDialogRef<CompleteDialogComponent>) {}

  logJournal() {
    this.dialogRef.close('journal');
  }

  viewProgress() {
    this.dialogRef.close('progress');
  }
}
