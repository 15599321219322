import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CourseService } from '@app/_services/course.service';
import { TranslateService } from '@ngx-translate/core';
import { utcToZonedTime } from 'date-fns-tz';

@Component({
  selector: 'app-week5-page5',
  templateUrl: './week5-page5.component.html',
  styleUrls: ['./week5-page5.style.scss'],
})
export class Week5Page5Component implements OnInit {
  constructor(
    private router: Router,
    private translate: TranslateService,
    private courseService: CourseService
  ) {}

  ngOnInit(): void {
    const savedLang = localStorage.getItem('selectedLanguage');
    if (savedLang) {
      this.translate.use(savedLang);
    } else {
      this.translate.use(this.translate.defaultLang);
    }

    this.translate.onLangChange.subscribe((event) => {
      localStorage.setItem('selectedLanguage', event.lang);
    });
  }

  onNextClick() {
    this.courseService.getCourseTimes().then(
      (response) => {
        // 4: Indicates the number of the current week
        if (response.currentWeek === 5) {
          const timeZone = 'America/Vancouver';
          const lastUpdateTimeVancouver = utcToZonedTime(
            response.lastUpdateTime,
            timeZone
          );
          const dayDifference = this.courseService.getDayDuration(
            new Date(),
            lastUpdateTimeVancouver,
            timeZone
          );
          // Paremeter(5): Indicates the number of the current week
          this.courseService.checkUserProgress(5, dayDifference, true);
        } else {
          this.router.navigate(['week5/complete_course']);
        }
      },
      (error) => {
        console.log('Userprogress not found!');
      }
    );
  }
}
