<app-header *ngIf="!showSpecificPatient"></app-header>
<div class="card-body" [ngClass]="showSpecificPatient ? 'p-0 mt-4' : ''">
  <!-- <div class="content-box-tsi"> -->
  <h3 class="card-header-date content-box-tsi">
    <app-filter></app-filter>
    <app-range-slider
      [name]="'headerslider'"
      [type]="'monthly'"
      (onWeekShift)="refreshWeekData($event)"
      (onDatePicked)="refreshCurrentEntry($event)"
    ></app-range-slider>
    <div class="row">
      <barchart [data]="allData"></barchart>
    </div>
    <!-- <div class="row" style="padding: 0 22px 0 89px">
      <div class="col" *ngFor="let val of allData">
        <app-donutchart [firstValue]="val.series[0].value > 0 ? val.series[0].value : -val.series[0].value" [secondValue]="(val.series[1].value)" [value]="(val.series[0].value/(val.series[0].value + val.series[1].value))*100| number:'2.0-0' "></app-donutchart>
      </div>
    </div> -->
    <!--Ref: https://heyoka.medium.com/scratch-made-svg-donut-pie-charts-in-html5-2c587e935d72 -->
    <!-- </div> -->

    <!-- caculations card -->
    <div class="row offset-1" style="margin-right: 8.33%; margin-top: 2.5em">
      <div class="col-md-6">
        <div class="row mb-2">
          <div class="col-4">
            <div
              style="
                width: 68.5px;
                height: 5px;
                left: 530px;
                top: 947px;

                border: 2.5px solid #055d9d;
              "
            ></div>
          </div>
          <div class="col-8">
            <span class="label-heading"
              >{{ "DIARY_AVERAGE_SLEEP_TIME" | translate }}
            </span>
            <span class="label-text">{{
              averageSleepTime ? (averageSleepTime | formatTime) : ""
            }}</span>
            <!-- <span class="label-text">{{ averageSleepTime | formatTime }}</span> -->
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-4">
            <div
              style="
                width: 68.5px;
                height: 5px;
                left: 530px;
                top: 947px;
                border: 2.5px dashed #6fc8f6;
              "
            ></div>
          </div>
          <div class="col-8">
            <span class="label-heading">
              {{ "DIARY_AVERAGE_TIME_BED" | translate }}
            </span>
            <span class="label-text">{{
              averageTimeInBed ? (averageTimeInBed | formatTime) : ""
            }}</span>
            <!-- <span class="label-text">{{ averageTimeInBed | formatTime }}</span> -->
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row mb-2">
          <div class="col-4">
            <img src="./assets/e.png" style="margin-left: 5px" />
            <!--<app-donutchart [firstValue]="averageSleepTime" [secondValue]="averageTimeInBed-averageSleepTime" [value]="averageSleepEfficiency"></app-donutchart>-->
            <!-- <app-donutchart  [firstValue]="averageSleepTime" [secondValue]="averageTimeInBed" [value]="'calculate'"></app-donutchart> -->
          </div>
          <div class="col-8">
            <span class="label-heading">
              {{ "DIARY_AVERAGE_SLEEP_EFFICIENCY" | translate }}
            </span>
            <span class="label-text">{{
              averageSleepEfficiency ? averageSleepEfficiency + "%" : ""
            }}</span>
            <!-- <span class="label-text">{{ averageSleepEfficiency }}%</span> -->
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-4">
            <svg
              width="51"
              height="51"
              viewBox="0 0 51 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.125 21.25L26.8831 27.0686C27.3016 27.3824 27.8912 27.3208 28.2357 26.9271L42.5 10.625"
                stroke="#055D9D"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M44.625 25.5C44.625 29.496 43.3733 33.3917 41.0457 36.6399C38.7181 39.8881 35.4315 42.3256 31.6475 43.61C27.8636 44.8945 23.7723 44.9615 19.9483 43.8015C16.1243 42.6415 12.7598 40.3128 10.3271 37.1426C7.89448 33.9723 6.51599 30.1197 6.38524 26.1258C6.25449 22.1318 7.37806 18.1973 9.59814 14.8747C11.8182 11.5521 15.0233 9.00841 18.7632 7.6008C22.5031 6.19319 26.59 5.99242 30.4499 7.02667"
                stroke="#055D9D"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>

            <!-- <i class="f-icons f-check">&nbsp;</i> -->
          </div>
          <div class="col-8">
            <span class="label-heading">
              {{ "DIARY_AVERAGE_SLEEP_QUALITY" | translate }}</span
            >
            <span class="label-text">{{
              averageRating ? averageRating + "/10" : ""
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- calculations card end -->
    <!--<h3 class="heading mt-4" style="margin-top: 2.5em;">Sleep Efficiency</h3>
    <div class="row" style="height:12em" #one>
      <div class="col ml-4 mr-4" #efficiencyContainer>
      <ngx-charts-bar-vertical [view]=""  [scheme]="colorScheme" [results]="sleepEfficiencyInit" [gradient]="gradient"
        [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel" [roundEdges]=true [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" [showDataLabel]="true" [dataLabelFormatting]="formatDataLabel">
      </ngx-charts-bar-vertical> -->
    <!-- <ngx-charts-bar-vertical
      [view]="[efficiencyContainer.offsetWidth, 400]"
  [scheme]="colorScheme"
  [results]="sleepEfficiencyInit"
  [gradient]="gradient"
  [xAxis]="showXAxis"
  [yAxis]="showYAxis"
  [legend]="showLegend"
  [showXAxisLabel]="showXAxisLabel"
  [showYAxisLabel]="showYAxisLabel"
  [xAxisLabel]="xAxisLabel"
  [yAxisLabel]="yAxisLabel"
  [yAxisTickFormatting]="yAxisFormat"
  [yAxisTicks]= [0,20,40,60,80,100]

  >
</ngx-charts-bar-vertical>
    </div>
    </div>
    <div class="pt-4">  -->
    <!-- <app-range-slider [name]="'headerslider'" [type]="'monthly'" (onWeekShift)="refreshWeekData($event)" (onDatePicked)="refreshCurrentEntry($event)" ></app-range-slider> -->
    <!--</div> -->
  </h3>
</div>
