import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CourseService } from '@app/_services/course.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-week2-page7',
  templateUrl: './week2-page7.component.html',
  styleUrls: ['./week2-page7.style.scss'],
})
export class Week2Page7Component implements OnInit {
  form: FormGroup;
  formError = false;
  doctorSuggestion: string = '';

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '100px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    placeholder: 'Enter text here ...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'heading',
        'fontName',
        'backgroundColor',
        'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'insertImage',
        'insertVideo',
        'insertLink',
        'toggleEditorMode',
      ],
    ],
  };

  constructor(
    private router: Router,
    private translate: TranslateService,
    private courseService: CourseService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      week_number: ['2'],
      self_assessment_text: ['', Validators.required],
      reflection_text: ['', Validators.required],
      doctor_suggestion: [''],
    });
  }

  ngOnInit(): void {
    const savedLang = localStorage.getItem('selectedLanguage');
    if (savedLang) {
      this.translate.use(savedLang);
    } else {
      this.translate.use(this.translate.defaultLang);
    }

    this.courseService.isStudied(2, 7).then((result) => {
      if (result) {
        this.courseService.getSelfAssessmentReflection(2).subscribe(
          (data) => {
            if (data) {
              this.form.patchValue({
                self_assessment_text: data.self_assessment_text,
                reflection_text: data.reflection_text,
                doctor_suggestion: data.doctor_suggestion,
              });
              this.doctorSuggestion = data?.doctor_suggestion;
            }
          },
          (error) => {
            console.error(
              'Error fetching self-assessment and reflection data.',
              error
            );
          }
        );
      }
    });

    this.translate.onLangChange.subscribe((event) => {
      localStorage.setItem('selectedLanguage', event.lang);
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.courseService.saveSelfAssessmentReflection(this.form.value).subscribe(
      (response) => {
        console.log(
          'Self-Assessment and reflection saved sucessfully',
          response
        );
      },
      (error) => {
        console.log('Self-Assessment and reflection saved failure', error);
      }
    );
    this.courseService.unlockWeek(1);
    this.courseService.unlockWeek2Content(7);
    this.courseService.updateProgress(2, 8);

    this.router.navigate(['week2/plan_for_the_week']);
  }
}
