import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AccountService } from './_services';
import { Account, Role } from './_models';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { CourseService } from './_services/course.service';

@Component({
  selector: 'app',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.style.scss'],
})
export class AppComponent implements OnInit {
  Role: String;
  account: Account;
  userEnum = Role.User;
  adminEnum = Role.Admin;
  accountsbs;
  router;
  selectedLanguage: string = 'en';

  // Add source for menu
  selectedWeek: number = -1;
  currentWeek: number = -1;
  manualTaggle: boolean = false;

  constructor(
    private accountService: AccountService,
    private _router: Router,
    private translate: TranslateService,
    public courseService: CourseService,
    private actRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {
    this.accountService.account.subscribe((x) => (this.account = x));
    this.accountsbs = this.accountService.accountValue;

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router = event.url;
        this.updateWeekFromUrl();
      }
    });

    // Translation setup code
    this.translate.addLangs(['en', 'sch', 'tch']);
    this.translate.setDefaultLang('en');
  }

  ngOnInit() {
    this.accountService.fetchAccountDataFromStorage();
    this.Role = this.accountService.Role;

    if (this.Role) {
      const savedLang = localStorage.getItem('selectedLanguage');
      if (savedLang) {
        this.selectedLanguage = savedLang;
        this.translate.use(savedLang);
      } else {
        this.selectedLanguage = this.translate.defaultLang;
      }

      if (this._router.url === '/account/login') {
        this.account = null;
        this.accountsbs = null;
      }

      this.courseService.getUserProgress().subscribe({
        next: (progress: any) => {
          if (progress && Object.keys(progress).length > 0) {
            this.courseService.unlockWeekAndContent(
              progress.current_week,
              progress.current_page
            );
            this.currentWeek = progress.current_week - 1;
          }
        },
        error: (error) => {
          console.error('Error fetching user progress:', error);
        },
      });
    }
  }

  handelLinkClick(url: string) {
    this.manualTaggle = true;
    this._router.navigate([url]);
  }

  updateWeekFromUrl(): void {
    const urlSegments = this._router.url.split('/');
    const weekSegment = urlSegments.find((segment) =>
      segment.startsWith('week')
    );
    if (weekSegment) {
      const weekNumber = parseInt(weekSegment.replace('week', ''), 10) - 1;

      if (!isNaN(weekNumber) && this.currentWeek !== weekNumber) {
        this.currentWeek = weekNumber;
        if (
          !this.manualTaggle &&
          !this.courseService.getWeekLocked(weekNumber)
        ) {
          this.toggleWeek(weekNumber);
        }
      }
    }
    this.manualTaggle = false;
  }

  // Language switch function
  switchLanguage(language: string) {
    this.selectedLanguage = language;
    localStorage.setItem('selectedLanguage', language);
    this.translate.use(language);
  }

  refreshMenu(): void {
    this.courseService.unlockWeek(0);
    this.courseService.unlockWeek1Content(0);
    this.cdr.detectChanges();
  }

  logout() {
    this.accountService.logout();
  }

  toggleWeek(weekIndex: number): void {
    if (!this.courseService.getWeekLocked(weekIndex)) {
      // this.selectedWeek = weekIndex;
      this.selectedWeek = this.selectedWeek === weekIndex ? -1 : weekIndex;
    }
  }

  isExpanded(weekIndex: number): boolean {
    // return this.selectedWeek === weekIndex + 1;
    return (
      // this.selectedWeek === weekIndex &&
      // !this.courseService.getWeek1ContentLocked(0)
      this.selectedWeek === weekIndex
    );
  }

  /**
   * Check if the router url contains the specified route
   *
   * @param {string} route
   * @returns
   * @memberof MyComponent
   */
  hasRoute(route: string) {
    return this.router == '/' + route;
  }

  /**
   * Check if the router url starts wtih the specified route
   *
   * @param {string} route
   * @returns
   * @memberof MyComponent
   */
  startsWithRoute(route: string) {
    return this.router && this.router.startsWith('/' + route);
  }
}
