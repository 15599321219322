import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { Week3Component } from './week3.component';
import { Week3Page1Component } from './week3-page1/week3-page1.component';
import { Week3Page2Component } from './week3-page2/week3-page2.component';
import { Week3Page3Component } from './week3-page3/week3-page3.component';
import { Week3Page4Component } from './week3-page4/week3-page4.component';
import { Week3Page5Component } from './week3-page5/week3-page5.component';

import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AngularEditorModule,
    RouterModule,
    TranslateModule.forChild(),
  ],
  declarations: [
    Week3Component,
    Week3Page1Component,
    Week3Page2Component,
    Week3Page3Component,
    Week3Page4Component,
    Week3Page5Component,
  ],
  exports: [Week3Page2Component],
})
export class Week3Module {}
