import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  ShowOnDirtyErrorStateMatcher,
  ErrorStateMatcher,
} from '@angular/material/core';
import { Options } from '@angular-slider/ngx-slider';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { first } from 'rxjs/operators';

import { AccountService, AlertService } from '@app/_services';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { DatePipe } from '@angular/common';

import {
  trigger,
  transition,
  query,
  style,
  animate,
  group,
  state,
} from '@angular/animations';
import { MyService } from './filter-service';
import { PatientService } from '@app/_services/patient.service';
import { Role } from '@app/_models';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null) {
    return control.invalid;
  }
}

@Component({
  selector: 'dairy-component',
  templateUrl: 'diary.component.html',
  styleUrls: ['diary.component.scss'],
  providers: [MyService],
})
export class DiaryComponent implements OnInit {
  @Input() showSpecificPatient: boolean;

  setview = 'weekly';
  sharedVar;
  page = 1;
  selectd = null;
  serializedDate = new FormControl(new Date().toISOString());
  form: FormGroup;
  loading = false;
  submitted = false;

  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;

  colorScheme = {
    domain: ['#055d9d', '#58a3ca'],
  };
  single = [
    {
      name: 'Time Asleep',
      value: 360,
    },
    {
      name: 'Time Awake',
      value: 120,
    },
  ];

  multi = [
    {
      name: 'Time Asleep',
      value: 360,
    },
    {
      name: 'Time Awake',
      value: 120,
    },
  ];
  //value for slider
  autoTicks = false;
  disabled = false;
  invert = false;
  //ideally max is dependent on input times
  max = 240;
  min = 0;
  showTicks = true;
  step = 5;
  thumbLabel = true;
  value = 0;
  vertical = false;
  hidden = true;

  get tickInterval(): number | 'auto' {
    return this.showTicks ? (this.autoTicks ? 'auto' : this._tickInterval) : 0;
  }
  set tickInterval(value) {
    this._tickInterval = coerceNumberProperty(value);
  }
  private _tickInterval = 5;

  //value for timepicker
  title = 'angular-material-timepicker';
  minValue: Date;
  maxValue: Date;
  defaultValue: Date;

  showOnDirtyErrorStateMatcher = new ShowOnDirtyErrorStateMatcher();
  customErrorStateMatcher = new CustomErrorStateMatcher();

  patientDetails: any;

  startdateofweek: any;
  Enddateofweek: string;
  model: any = {};
  date = new Date();
  currentFilter: string = 'weekly';
  selectedLanguage: string = 'en';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService,
    private alertService: AlertService,
    private datePipe: DatePipe,
    private myService: MyService,
    private patientService: PatientService,
    private translate: TranslateService
  ) {
    this.sharedVar = this.myService.subj$;
    this.sharedVar.subscribe(
      (x) => {
        this.currentFilter = x;
      },
      (err) => console.error('Observer got an error: ' + err),
      () => console.log('Observer got a complete notification')
    );
    // const minValue = new Date();
    // minValue.setHours(6);
    // minValue.setMinutes(10);
    // this.minValue = minValue;

    // const maxValue = new Date();
    // maxValue.setHours(18);
    // maxValue.setMinutes(10);
    // this.maxValue = maxValue;

    // const d = new Date();
    // d.setDate(1);
    // d.setMonth(2);
    // d.setHours(7);
    // d.setMinutes(0);
    // d.setSeconds(1);
    // d.setMilliseconds(10);
    // this.defaultValue = d;

    // this.form = this.formBuilder.group({
    //   time: [this.defaultValue, Validators.required],
    // });
  }

  currentDate: Date = new Date();
  async ngOnInit() {
    let role = this.accountService.Role;

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem('selectedLanguage', event.lang);
    });

    if (role === Role.User) {
      let userDetails = await JSON.parse(localStorage.getItem('token'));
      this.patientService
        .getPatientDetailsById(userDetails.user.id)
        .pipe(first())
        .subscribe({
          next: (data) => {
            data.initial_letter = data.first_name.charAt(0);
            this.patientDetails = data;
          },
          error: (error) => {
            this.alertService.error(error);
          },
        });
    } else {
      this.patientDetails = this.patientService.patientData;
    }
    // this.form = this.formBuilder.group({
    //   email: ['', [Validators.required, Validators.email]],
    //   password: ['', Validators.required],
    // });
    //  this.model.startdate = new Date();
    //  this.currentDate = this.datePipe.transform(this.model.startdate, 'yyyy,M,d');
    //  this.dt = new Date(this.getdate);
    //  this.startdateofweek= this.datePipe.transform(this.startOfWeek(this.dt),'EEEE, MMMM d, y');
    //  this.Enddateofweek=this.datePipe.transform(this.endofweek(this.dt),'EEEE, MMMM d, y');
  }

  // Ref:https://www.c-sharpcorner.com/article/how-to-get-start-and-end-date-of-week-using-angular-10ngular/
  // startOfWeek(date) {
  //   var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
  //   return new Date(date.setDate(diff));
  // }
  // endofweek(date) {
  //   var lastday = date.getDate() - (date.getDay() - 1) + 6;
  //   return new Date(date.setDate(lastday));
  // }

  addDays(date, days) {
    const find = new Date(Number(date));
    find.setDate(date.getDate() + days);
    return find;
  }

  shiftweek(attr) {
    if (attr == 0) {
      this.currentDate = this.addDays(this.currentDate, -7);
    } else {
      this.currentDate = this.addDays(this.currentDate, 7);
    }
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }
  //for changing views
  changeView(view) {
    this.setview = view;
  }

  showSlider() {
    this.hidden = !this.hidden;
  }
  hideSlider() {
    this.hidden = true;
  }

  pageTwo() {
    this.page = 2;
  }
  pageOne() {
    this.page = 1;
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }
}
