import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { CourseService } from '@app/_services/course.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-week3-page2',
  templateUrl: './week3-page2.component.html',
  styleUrls: ['./week3-page2.style.scss'],
})
export class Week3Page2Component implements OnInit {
  @Input() id: number;
  @Input() role: number;
  form: FormGroup;
  // Form fields for sleep journal data
  goToBedTime: string = '';
  goToSleepTime: string = '';
  minutesAwake: string = '';
  wakeUpTime: string = '';
  riseFromBedTime: string = '';

  // Calculated results
  totalTimeInBed: string = '';
  totalSleepTime: string = '';
  sleepEfficiency: string = '';

  lastBedToLightsOffChkFlg;
  lastLightsOffToSleepChkFlg;
  lastSleepToWakeChkFlg;
  lastWakeToRiseChkFlg;

  lastGoToBedTime = 0;
  lastLightsOffTime = 0;
  lastGoToSleepTime = 0;
  lastWakeUpTime = 0;
  lastRiseFromBedTime = 0;

  entry_date_noon: Date;
  next_entry_date_noon: Date;
  go_to_bed_date_time: Date;
  lights_off_date_time: Date;
  go_to_sleep_date_time: Date;
  wake_up_date_time: Date;
  rise_from_bed_date_time: Date;

  hasFirstPreviousData: boolean = false;
  hasLastNextData: boolean = false;

  journalEntries = [];

  constructor(
    private router: Router,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private courseService: CourseService,
    private fb: FormBuilder,
    private alertService: AlertService
  ) {
    this.form = this.fb.group({
      totalAwakeNumber: [''],
      totalAwakeMinutes: [''],
      totalTimeInBed: [''],
      totalSleepTime: [''],
      sleepEfficiency: [''],
    });
  }

  ngOnInit(): void {
    const savedLang = localStorage.getItem('selectedLanguage');
    if (savedLang) {
      this.translate.use(savedLang);
    } else {
      this.translate.use(this.translate.defaultLang);
    }
    let previousData: any = {};
    let firstPreviousData: any = {};
    let lastNextData: any = {};

    if (!this.id) {
      this.id = JSON.parse(localStorage.getItem('token')).user.id;
    }

    // Parameter(2): Indicates the number of the previous week
    this.courseService
      .getLastWeekSleepJournalCalculateEntries(2, this.id)
      .subscribe({
        next: (response) => {
          if (response && response.length > 0) {
            if (response.length === 7) {
              for (const [index, entry] of response.entries()) {
                this.setSleepTime(entry);

                if (
                  this.go_to_bed_date_time >= this.entry_date_noon &&
                  this.rise_from_bed_date_time <= this.next_entry_date_noon
                ) {
                  this.setDefaultFlg(entry);
                }

                if (this.hasPreviousData(entry) && index === 0) {
                  this.setFirstPreviousData(entry, firstPreviousData);
                }

                if (this.hasNextData(entry) && index > 0) {
                  this.setNextData(entry, previousData);
                }

                if (this.hasLastData(previousData)) {
                  this.setLastData(previousData, entry);
                }

                if (this.hasLastData(entry) && index === 6) {
                  lastNextData = this.addLastEntry(entry);
                }
                // this.chartDisplayFlg(entry);
                entry.date = entry.entry_date;
                entry.next_date = moment(entry.entry_date).add(1, 'days');

                previousData = entry;
              }
              this.journalEntries = response;
              if (this.hasFirstPreviousData) {
                this.journalEntries.unshift(firstPreviousData);
              }
              if (this.hasLastNextData) {
                this.journalEntries.push(lastNextData);
              }

              // Parameter (2): Indicates the number of the previous week.
              this.courseService
                .getLastWeekSleepJournalCalculateTotal(2, this.id)
                .subscribe((data) => {
                  let totalAwakeNumber = 0;
                  let totalAwakeMinutes = 0;
                  let totalTimeInBed = 0;
                  let totalSleepTime = 0;
                  let startDate;
                  let endDate;

                  if (data && Object.keys(data).length > 0) {
                    totalAwakeNumber = data.total_awake_number ?? 0;
                    totalAwakeMinutes = data.total_awake_minutes ?? 0;
                    totalTimeInBed = data.total_time_in_bed ?? 0;
                    totalSleepTime = data.total_sleep_time ?? 0;

                    this.form.patchValue({
                      sleepEfficiency: data.sleep_efficiency,
                    });
                  } else {
                    for (const [index, entry] of response.entries()) {
                      const awakeMinutes = entry.minutes_awake || 0; // If undefined, use 0
                      const awakeNumber = entry.number_awakening || 0;
                      const timeInBed = entry.time_in_bed || 0;
                      const sleepTime = entry.sleep_time || 0;

                      if (index === 0) {
                        if (entry.entry_date) {
                          startDate = entry.entry_date;
                        } else {
                          startDate = entry.next_date;
                        }
                      } else {
                        if (entry.entry_date) {
                          endDate = entry.entry_date;
                        }
                      }

                      totalAwakeNumber += awakeNumber;
                      totalAwakeMinutes += awakeMinutes;
                      totalTimeInBed += timeInBed;
                      totalSleepTime += sleepTime;
                    }

                    const totalSleepEfficiency = (
                      (totalSleepTime / totalTimeInBed) *
                      100
                    ).toFixed(0); // Format as percentage

                    this.form.patchValue({
                      sleepEfficiency: totalSleepEfficiency + '%',
                    });

                    // 2: Indicates the number of the previous week.
                    let request = {
                      week_number: 2,
                      start_date: moment(startDate).format('YYYY-MM-DD'),
                      end_date: moment(endDate).format('YYYY-MM-DD'),
                      total_awake_number: totalAwakeNumber,
                      total_awake_minutes: totalAwakeMinutes,
                      total_time_in_bed: totalTimeInBed,
                      total_sleep_time: totalSleepTime,
                      sleep_efficiency: totalSleepEfficiency + '%',
                    };

                    this.courseService.saveLastWeekSleepJournalCalculateTotal(
                      request
                    );
                  }
                  this.form.patchValue({
                    totalAwakeNumber: totalAwakeNumber,
                    totalAwakeMinutes: totalAwakeMinutes,
                    totalTimeInBed: totalTimeInBed,
                    totalSleepTime: totalSleepTime,
                  });
                });
            } else {
              console.error(
                'Error: Insufficient sleep journal entries. There should be exactly 7 entries.'
              );
              this.alertService.error(
                'Error: Insufficient sleep journal entries. There should be exactly 7 entries.'
              );
              return;
            }
          } else {
            // Parameter (2): Indicates the number of the previous week.
            this.courseService.getLastWeekJournalEntries(2).subscribe({
              next: (response) => {
                // Step 1: Check if the number of records is exactly 7
                if (response && response.length > 0) {
                  if (response.length === 7) {
                    let totalAwakeNumber = 0;
                    let totalAwakeMinutes = 0;
                    let totalTimeInBed = 0;
                    let totalSleepTime = 0;
                    let startDate;
                    let endDate;

                    for (const [index, entry] of response.entries()) {
                      this.initialSleepTime(entry);

                      this.setTableValue(entry);

                      if (index === 0) {
                        startDate = entry.entry_date;
                      }
                      if (index === 6) {
                        endDate = entry.entry_date;
                      }

                      totalAwakeNumber += entry.awake_number;
                      totalAwakeMinutes += entry.awake_minutes;
                      totalTimeInBed += entry.time_in_bed;
                      totalSleepTime += entry.sleep_time;

                      if (
                        this.go_to_bed_date_time >= this.entry_date_noon &&
                        this.rise_from_bed_date_time <=
                          this.next_entry_date_noon
                      ) {
                        this.setDefaultFlg(entry);
                      } else {
                        if (this.hasPreviousData(entry) && index === 0) {
                          this.setFirstPreviousData(entry, firstPreviousData);
                        }

                        if (this.hasNextData(entry) && index > 0) {
                          this.setNextData(entry, previousData);
                        }

                        if (this.hasLastData(previousData)) {
                          this.setLastData(previousData, entry);
                        }

                        if (this.hasLastData(entry) && index === 6) {
                          lastNextData = this.addLastEntry(entry);
                        }
                        entry.date = entry.entry_date;
                        entry.next_date = moment(entry.entry_date).add(
                          1,
                          'days'
                        );
                      }
                      previousData = entry;

                      // Parameter (2): Indicates the number of the previous week.
                      this.courseService.saveLastWeekSleepJournalCalculate(
                        entry,
                        2
                      );
                    }

                    this.journalEntries = response;
                    if (this.hasFirstPreviousData) {
                      this.journalEntries.unshift(firstPreviousData);
                    }
                    if (this.hasLastNextData) {
                      this.journalEntries.push(lastNextData);
                    }

                    const totalSleepEfficiency = (
                      (totalSleepTime / totalTimeInBed) *
                      100
                    ).toFixed(0); // Format as percentage

                    this.form.patchValue({
                      totalAwakeNumber: totalAwakeNumber,
                      totalAwakeMinutes: totalAwakeMinutes,
                      totalTimeInBed: totalTimeInBed,
                      totalSleepTime: totalSleepTime,
                      sleepEfficiency: totalSleepEfficiency + '%',
                    });

                    // 2: Indicates the number of the previous week
                    let request = {
                      week_number: 2,
                      start_date: moment(startDate).format('YYYY-MM-DD'),
                      end_date: moment(endDate).format('YYYY-MM-DD'),
                      total_awake_number: totalAwakeNumber,
                      total_awake_minutes: totalAwakeMinutes,
                      total_time_in_bed: totalTimeInBed,
                      total_sleep_time: totalSleepTime,
                      sleep_efficiency: totalSleepEfficiency + '%',
                    };

                    this.courseService.saveLastWeekSleepJournalCalculateTotal(
                      request
                    );
                  } else {
                    console.error(
                      'Error: Insufficient sleep journal entries. There should be exactly 7 entries.'
                    );
                    this.alertService.error(
                      'Error: Insufficient sleep journal entries. There should be exactly 7 entries.'
                    );
                    return;
                  }
                } else {
                  console.error('No data retrieved from sleep journal');
                }
              },
            });
          }
        },
      });

    this.translate.onLangChange.subscribe((event) => {
      localStorage.setItem('selectedLanguage', event.lang);
    });
  }
  setSleepTime(entry) {
    this.entry_date_noon = new Date(`${entry.entry_date}T12:00:00`);
    this.next_entry_date_noon = new Date(`${entry.entry_date}T12:00:00`);
    this.next_entry_date_noon.setDate(this.next_entry_date_noon.getDate() + 1);

    this.go_to_bed_date_time = new Date(entry.go_to_bed_date_time);
    this.lights_off_date_time = new Date(entry.lights_off_date_time);
    this.go_to_sleep_date_time = new Date(entry.go_to_sleep_date_time);
    this.wake_up_date_time = new Date(entry.wake_up_date_time);
    this.rise_from_bed_date_time = new Date(entry.rise_from_bed_date_time);
  }

  hasPreviousData(entry): boolean {
    const entry_date_noon = new Date(`${entry.entry_date}T12:00:00`);
    const go_to_bed_date_time = new Date(entry.go_to_bed_date_time);

    if (go_to_bed_date_time < entry_date_noon) {
      return true;
    } else {
      return false;
    }
  }

  hasNextData(entry): boolean {
    const entry_date_noon = new Date(`${entry.entry_date}T12:00:00`);
    const go_to_bed_date_time = new Date(entry.go_to_bed_date_time);

    if (go_to_bed_date_time < entry_date_noon) {
      return true;
    } else {
      return false;
    }
  }

  setDefaultFlg(entry) {
    entry.bedToLightsOffChkFlg = 0;
    entry.lightsOffToSleepChkFlg = 0;
    entry.sleepToWakeChkFlg = 0;
    entry.wakeToRiseChkFlg = 0;
    entry.date = entry.entry_date;
    entry.next_date = moment(entry.entry_date).add(1, 'days');
  }

  setFirstPreviousData(entry, firstPreviousData): object {
    firstPreviousData.bedToLightsOffChkFlg = 2;
    firstPreviousData.lightsOffToSleepChkFlg = 2;
    firstPreviousData.sleepToWakeChkFlg = 2;
    firstPreviousData.wakeToRiseChkFlg = 2;
    firstPreviousData.date = moment(entry.entry_date).subtract(1, 'days');
    firstPreviousData.next_date = entry.entry_date;

    this.hasFirstPreviousData = true;

    entry.bedToLightsOffChkFlg = 2;
    entry.lightsOffToSleepChkFlg = 2;
    entry.sleepToWakeChkFlg = 2;
    entry.wakeToRiseChkFlg = 2;

    if (this.rise_from_bed_date_time < this.entry_date_noon) {
      firstPreviousData.previous_go_to_bed_date_time =
        entry.go_to_bed_date_time;
      firstPreviousData.previous_lights_off_date_time =
        entry.lights_off_date_time;
      firstPreviousData.previous_go_to_sleep_date_time =
        entry.go_to_sleep_date_time;
      firstPreviousData.previous_wake_up_date_time = entry.wake_up_date_time;
      firstPreviousData.previous_rise_from_bed_date_time =
        entry.rise_from_bed_date_time;
      firstPreviousData.previousRiseChkFlg = 1;
    } else if (
      this.wake_up_date_time < this.entry_date_noon &&
      this.rise_from_bed_date_time >= this.entry_date_noon
    ) {
      firstPreviousData.previous_go_to_bed_date_time =
        entry.go_to_bed_date_time;
      firstPreviousData.previous_lights_off_date_time =
        entry.lights_off_date_time;
      firstPreviousData.previous_go_to_sleep_date_time =
        entry.go_to_sleep_date_time;
      firstPreviousData.previous_wake_up_date_time = entry.wake_up_date_time;
      firstPreviousData.previousWakeToRiseChkFlg = 1;
      entry.previousWakeToRiseChkFlg = 0;
    } else if (
      this.go_to_sleep_date_time < this.entry_date_noon &&
      this.wake_up_date_time >= this.entry_date_noon
    ) {
      firstPreviousData.previous_go_to_bed_date_time =
        entry.go_to_bed_date_time;
      firstPreviousData.previous_lights_off_date_time =
        entry.lights_off_date_time;
      firstPreviousData.previous_go_to_sleep_date_time =
        entry.go_to_sleep_date_time;
      firstPreviousData.previousSleepToWakeChkFlg = 1;
      entry.previousSleepToWakeChkFlg = 0;
      entry.wakeToRiseChkFlg = 0;
    } else if (
      this.lights_off_date_time < this.entry_date_noon &&
      this.go_to_sleep_date_time >= this.entry_date_noon
    ) {
      firstPreviousData.previous_go_to_bed_date_time =
        entry.go_to_bed_date_time;
      firstPreviousData.previous_lights_off_date_time =
        entry.lights_off_date_time;
      firstPreviousData.previousLightsOffToSleepChkFlg = 1;
      entry.previousLightsOffToSleepChkFlg = 0;
      entry.sleepToWakeChkFlg = 0;
      entry.wakeToRiseChkFlg = 0;
    } else if (
      this.go_to_bed_date_time < this.entry_date_noon &&
      this.lights_off_date_time >= this.entry_date_noon
    ) {
      firstPreviousData.previous_go_to_bed_date_time =
        entry.go_to_bed_date_time;
      firstPreviousData.previousBedToLightsOffChkFlg = 1;
      entry.previousBedToLightsOffChkFlg = 0;
      entry.lightsOffToSleepChkFlg = 0;
      entry.sleepToWakeChkFlg = 0;
      entry.wakeToRiseChkFlg = 0;
    }
    return firstPreviousData;
  }

  setNextData(entry, previousData) {
    if (this.rise_from_bed_date_time < this.entry_date_noon) {
      previousData.previous_go_to_bed_date_time = entry.go_to_bed_date_time;
      previousData.previous_lights_off_date_time = entry.lights_off_date_time;
      previousData.previous_go_to_sleep_date_time = entry.go_to_sleep_date_time;
      previousData.previous_wake_up_date_time = entry.wake_up_date_time;
      previousData.previous_rise_from_bed_date_time =
        entry.rise_from_bed_date_time;
      previousData.previousRiseChkFlg = 1;
    } else if (
      this.wake_up_date_time < this.entry_date_noon &&
      this.rise_from_bed_date_time >= this.entry_date_noon
    ) {
      previousData.previous_go_to_bed_date_time = entry.go_to_bed_date_time;
      previousData.previous_lights_off_date_time = entry.lights_off_date_time;
      previousData.previous_go_to_sleep_date_time = entry.go_to_sleep_date_time;
      previousData.previous_wake_up_date_time = entry.wake_up_date_time;
      previousData.previousWakeToRiseChkFlg = 1;
      entry.previousWakeToRiseChkFlg = 0;
    } else if (
      this.go_to_sleep_date_time < this.entry_date_noon &&
      this.wake_up_date_time >= this.entry_date_noon
    ) {
      previousData.previous_go_to_bed_date_time = entry.go_to_bed_date_time;
      previousData.previous_lights_off_date_time = entry.lights_off_date_time;
      previousData.previous_go_to_sleep_date_time = entry.go_to_sleep_date_time;
      previousData.previousSleepToWakeChkFlg = 1;
      entry.previousSleepToWakeChkFlg = 0;
      entry.wakeToRiseChkFlg = 0;
    } else if (
      this.lights_off_date_time < this.entry_date_noon &&
      this.go_to_sleep_date_time >= this.entry_date_noon
    ) {
      previousData.previous_go_to_bed_date_time = entry.go_to_bed_date_time;
      previousData.previous_lights_off_date_time = entry.lights_off_date_time;
      previousData.previousLightsOffToSleepChkFlg = 1;
      entry.previousLightsOffToSleepChkFlg = 0;
      entry.sleepToWakeChkFlg = 0;
      entry.wakeToRiseChkFlg = 0;
    } else if (
      this.go_to_bed_date_time < this.entry_date_noon &&
      this.lights_off_date_time >= this.entry_date_noon
    ) {
      previousData.previous_go_to_bed_date_time = entry.go_to_bed_date_time;
      previousData.previousBedToLightsOffChkFlg = 1;
      entry.previousBedToLightsOffChkFlg = 0;
      entry.lightsOffToSleepChkFlg = 0;
      entry.sleepToWakeChkFlg = 0;
      entry.wakeToRiseChkFlg = 0;
    }
  }

  setLastData(previousData, entry) {
    const last_next_entry_date_noon = new Date(`${entry.entry_date}T12:00:00`);

    const last_go_to_bed_date_time = new Date(previousData.go_to_bed_date_time);
    const last_lights_off_date_time = new Date(
      previousData.lights_off_date_time
    );
    const last_go_to_sleep_date_time = new Date(
      previousData.go_to_sleep_date_time
    );
    const last_wake_up_date_time = new Date(previousData.wake_up_date_time);
    const last_rise_from_bed_date_time = new Date(
      previousData.rise_from_bed_date_time
    );

    if (
      last_wake_up_date_time <= last_next_entry_date_noon &&
      last_rise_from_bed_date_time > last_next_entry_date_noon
    ) {
      // entry.nextWakeToRiseChkFlg = 1;
      previousData.bedToLightsOffChkFlg = 0;
      previousData.lightsOffToSleepChkFlg = 0;
      previousData.sleepToWakeChkFlg = 0;
      previousData.lastWakeToRiseChkFlg = 0;

      entry.lastWakeToRiseChkFlg = 1;
      entry.wakeToRiseChkFlg = 2;
      entry.last_rise_from_bed_date_time = previousData.rise_from_bed_date_time;
    } else if (
      last_go_to_sleep_date_time <= last_next_entry_date_noon &&
      last_wake_up_date_time > last_next_entry_date_noon
    ) {
      previousData.bedToLightsOffChkFlg = 0;
      previousData.lightsOffToSleepChkFlg = 0;
      previousData.lastSleepToWakeChkFlg = 0;

      // entry.sleepToWakeChkFlg = 2;
      entry.lastSleepToWakeChkFlg = 1;
      entry.last_wake_up_date_time = previousData.wake_up_date_time;
      entry.last_rise_from_bed_date_time = previousData.rise_from_bed_date_time;
    } else if (
      last_lights_off_date_time <= last_next_entry_date_noon &&
      last_go_to_sleep_date_time > last_next_entry_date_noon
    ) {
      previousData.bedToLightsOffChkFlg = 0;
      previousData.lastLightsOffToSleepChkFlg = 0;

      // entry.lightsOffToSleepChkFlg = 2;
      entry.lastLightsOffToSleepChkFlg = 1;
      entry.last_go_to_sleep_date_time = previousData.go_to_sleep_date_time;
      entry.last_wake_up_date_time = previousData.wake_up_date_time;
      entry.last_rise_from_bed_date_time = previousData.rise_from_bed_date_time;
    }
  }

  hasLastData(entry): boolean {
    const next_entry_date_noon = new Date(`${entry.entry_date}T12:00:00`);
    next_entry_date_noon.setDate(next_entry_date_noon.getDate() + 1);
    const rise_from_bed_date_time = new Date(entry.rise_from_bed_date_time);
    if (rise_from_bed_date_time > next_entry_date_noon) {
      return true;
    } else {
      return false;
    }
  }

  initialSleepTime(entry) {
    this.entry_date_noon = new Date(`${entry.entry_date}T12:00:00`);
    this.next_entry_date_noon = new Date(`${entry.entry_date}T12:00:00`);
    this.next_entry_date_noon.setDate(this.next_entry_date_noon.getDate() + 1);

    this.go_to_bed_date_time = new Date(entry.go_to_bed_date_time);
    this.lights_off_date_time = new Date(entry.lights_off_date_time);

    entry.go_to_sleep_date_time = this.calculateGoToSleepTime(
      entry.lights_off_date_time,
      entry.duration_falling_sleep
    );
    this.go_to_sleep_date_time = new Date(entry.go_to_sleep_date_time);

    this.wake_up_date_time = new Date(entry.wake_up_date_time);
    this.rise_from_bed_date_time = new Date(entry.rise_from_bed_date_time);
  }

  // isDateEqual(date1: string, date2: string): boolean {
  //   // Create date objects from strings
  //   const dateOnly1 = new Date(date1);
  //   const dateOnly2 = new Date(date2);

  //   // Extract the date parts for comparison
  //   const date1String = dateOnly1.toISOString().split('T')[0];
  //   const date2String = dateOnly2.toISOString().split('T')[0];

  //   // Return true if the dates are the same, false otherwise
  //   return date1String === date2String;
  // }

  // isNextDateEqual(date1: string, date2: string): boolean {
  //   // Create Date objects from strings
  //   const dateOnly1 = new Date(date1);
  //   const dateOnly2 = new Date(date2);

  //   // Add one day to dateOnly1
  //   dateOnly1.setDate(dateOnly1.getDate() + 1);

  //   // Extract the date parts for comparison
  //   const date1String = dateOnly1.toISOString().split('T')[0];
  //   const date2String = dateOnly2.toISOString().split('T')[0];

  //   // Return true if date1 + 1 day is equal to date2, false otherwise
  //   return date1String === date2String;
  // }

  // chartDisplayFlg(entry: any) {
  //   // const goToBedTime = this.parseTimeMinutes(entry.go_to_bed_date_time);
  //   // const lightsOffTime = this.parseTimeMinutes(entry.lights_off_date_time);
  //   // const goToSleepTime = this.parseTimeMinutes(entry.go_to_sleep_date_time);
  //   // const wakeUpTime = this.parseTimeMinutes(entry.wake_up_date_time);
  //   // const riseFromBedTime = this.parseTimeMinutes(
  //   //   entry.rise_from_bed_date_time
  //   // );

  //   const next_entry_date_noon = new Date(`${entry.entry_date}T12:00:00`);
  //   next_entry_date_noon.setDate(next_entry_date_noon.getDate() + 1);
  //   const go_to_bed_date_time = new Date(entry.go_to_bed_date_time);
  //   const lights_off_date_time = new Date(entry.lights_off_date_time);
  //   const go_to_sleep_date_time = new Date(entry.go_to_sleep_date_time);
  //   const wake_up_date_time = new Date(entry.wake_up_date_time);
  //   const rise_from_bed_date_time = new Date(entry.rise_from_bed_date_time);

  //   // entry.bedToLightsOffChkFlg = 0;
  //   // entry.lightsOffToSleepChkFlg = 0;
  //   // entry.sleepToWakeChkFlg = 0;
  //   // entry.wakeToRiseChkFlg = 0;

  //   if (this.lastBedToLightsOffChkFlg === 1) {
  //     entry.lastBedToLightsOffChkFlg = 1;
  //     entry.lastLightsOffTime = this.lastLightsOffTime;
  //     entry.lastGoToSleepTime = this.lastGoToSleepTime;
  //     entry.lastWakeUpTime = this.lastWakeUpTime;
  //     entry.lastRiseFromBedTime = this.lastRiseFromBedTime;

  //     this.lastBedToLightsOffChkFlg = 0;
  //     // this.lastGoToSleepTime = 0;
  //     // this.lastWakeUpTime = 0;
  //     // this.lastRiseFromBedTime = 0;
  //   } else if (this.lastLightsOffToSleepChkFlg === 1) {
  //     entry.lastLightsOffToSleepChkFlg = 1;
  //     entry.lastGoToSleepTime = this.lastGoToSleepTime;
  //     entry.lastWakeUpTime = this.lastWakeUpTime;
  //     entry.lastRiseFromBedTime = this.lastRiseFromBedTime;

  //     this.lastLightsOffToSleepChkFlg = 0;
  //     // this.lastGoToSleepTime = 0;
  //     // this.lastWakeUpTime = 0;
  //     // this.lastRiseFromBedTime = 0;
  //   } else if (this.lastSleepToWakeChkFlg === 1) {
  //     entry.lastSleepToWakeChkFlg = 1;
  //     entry.lastWakeUpTime = this.lastWakeUpTime;
  //     entry.lastRiseFromBedTime = this.lastRiseFromBedTime;

  //     this.lastSleepToWakeChkFlg = 0;
  //     // this.lastWakeUpTime = 0;
  //     // this.lastRiseFromBedTime = 0;
  //   } else if (this.lastWakeToRiseChkFlg === 1) {
  //     entry.lastWakeToRiseChkFlg = 1;
  //     entry.lastRiseFromBedTime = this.lastRiseFromBedTime;
  //     this.lastWakeToRiseChkFlg = 0;
  //     // this.lastRiseFromBedTime = 0;
  //   }

  // if (
  //   go_to_bed_date_time <= next_entry_date_noon &&
  //   lights_off_date_time > next_entry_date_noon
  // ) {
  //   entry.bedToLightsOffChkFlg = 1;
  //   this.lastBedToLightsOffChkFlg = 1;
  //   this.lastLightsOffTime = entry.lights_off_date_time;
  //   this.lastGoToSleepTime = entry.go_to_sleep_date_time;
  //   this.lastWakeUpTime = entry.wake_up_date_time;
  //   this.lastRiseFromBedTime = entry.rise_from_bed_date_time;
  //   entry.lightsOffToSleepChkFlg = 2;
  //   entry.sleepToWakeChkFlg = 2;
  //   entry.wakeToRiseChkFlg = 2;
  // }

  //   if (
  //     lights_off_date_time <= next_entry_date_noon &&
  //     go_to_sleep_date_time > next_entry_date_noon
  //   ) {
  //     entry.lightsOffToSleepChkFlg = 1;
  //     this.lastLightsOffToSleepChkFlg = 1;

  //     this.lastGoToSleepTime = entry.go_to_sleep_date_time;
  //     this.lastWakeUpTime = entry.wake_up_date_time;
  //     this.lastRiseFromBedTime = entry.rise_from_bed_date_time;
  //     entry.bedToLightsOffChkFlg = 0;
  //     entry.sleepToWakeChkFlg = 2;
  //     entry.wakeToRiseChkFlg = 2;
  //   } else if (
  //     go_to_sleep_date_time <= next_entry_date_noon &&
  //     wake_up_date_time > next_entry_date_noon
  //   ) {
  //     entry.sleepToWakeChkFlg = 1;
  //     this.lastSleepToWakeChkFlg = 1;

  //     this.lastWakeUpTime = entry.wake_up_date_time;
  //     this.lastRiseFromBedTime = entry.rise_from_bed_date_time;
  //     entry.bedToLightsOffChkFlg = 0;
  //     entry.lightsOffToSleepChkFlg = 0;
  //     entry.wakeToRiseChkFlg = 2;
  //   } else if (
  //     wake_up_date_time <= next_entry_date_noon &&
  //     rise_from_bed_date_time > next_entry_date_noon
  //   ) {
  //     entry.wakeToRiseChkFlg = 1;
  //     this.lastWakeToRiseChkFlg = 1;
  //     this.lastRiseFromBedTime = entry.rise_from_bed_date_time;
  //     entry.bedToLightsOffChkFlg = 0;
  //     entry.lightsOffToSleepChkFlg = 0;
  //     entry.sleepToWakeChkFlg = 0;
  //   }
  // }

  setPageValue(entry: any) {
    const goToBedTime = this.parseTimeMinutes(entry.go_to_bed_date_time);
    const lightsOffTime = this.parseTimeMinutes(entry.lights_off_date_time);
    const wakeUpTime = this.parseTimeMinutes(entry.wake_up_date_time);
    const riseFromBedTime = this.parseTimeMinutes(
      entry.rise_from_bed_date_time
    );
    const durationFallingSleep = entry.duration_falling_sleep;
    const awakeMinutes = entry.minutes_awake || 0; // If undefined, use 0
    const awakeNumber = entry.number_awakening || 0;

    let riseFromBedTimeAdd = riseFromBedTime;
    if (
      (riseFromBedTime < 12 * 60 && goToBedTime > 12 * 60) ||
      riseFromBedTime < goToBedTime
    ) {
      riseFromBedTimeAdd += 24 * 60; // Add 1 day (24 hours)
    }
    const timeInBed = riseFromBedTimeAdd - goToBedTime;

    let goToSleepTime = lightsOffTime;
    if (durationFallingSleep > 0) {
      goToSleepTime += durationFallingSleep;
      if (goToSleepTime >= 24 * 60) {
        goToSleepTime = goToSleepTime % (24 * 60);
      }
    }

    let wakeUpTimeAdd = wakeUpTime;
    if (
      (wakeUpTime < 12 * 60 && lightsOffTime > 12 * 60) ||
      wakeUpTime < lightsOffTime
    ) {
      wakeUpTimeAdd += 24 * 60; // Add 1 day (24 hours)
    }
    const sleepTime =
      wakeUpTimeAdd - lightsOffTime - durationFallingSleep - awakeMinutes;

    const sleepEfficiency = ((sleepTime / timeInBed) * 100).toFixed(0); // Format as percentage

    entry.go_to_sleep_date_time = this.calculateGoToSleepTime(
      entry.lights_off_date_time,
      entry.duration_falling_sleep
    );

    entry.awake_number = awakeNumber;
    entry.awake_minutes = awakeMinutes;

    entry.time_in_bed = timeInBed;
    entry.sleep_time = sleepTime;
    entry.sleep_efficiency = sleepEfficiency + '%';
  }

  setTableValue(entry: any) {
    entry.awake_number = entry.number_awakening || 0;
    entry.awake_minutes = entry.minutes_awake || 0;

    const bedDifferenceInMs =
      this.rise_from_bed_date_time.getTime() -
      this.go_to_bed_date_time.getTime();
    const bedDifferenceInMinutes = Math.floor(bedDifferenceInMs / (1000 * 60));
    entry.time_in_bed = bedDifferenceInMinutes;

    const sleepDifferenceInMs =
      this.wake_up_date_time.getTime() - this.go_to_sleep_date_time.getTime();
    const sleepDifferenceInMinutes = Math.floor(
      (sleepDifferenceInMs - entry.awake_minutes) / (1000 * 60)
    );
    entry.sleep_time = sleepDifferenceInMinutes;

    const sleepEfficiency = (
      (sleepDifferenceInMinutes / bedDifferenceInMinutes) *
      100
    ).toFixed(0);
    entry.sleep_efficiency = sleepEfficiency + '%';
  }

  addLastEntry(entry: any): object {
    let newEntry: any = {};

    newEntry.date = moment(entry.entry_date).add(1, 'days');
    newEntry.next_date = moment(entry.entry_date).add(2, 'days');

    this.hasLastNextData = true;

    if (
      this.wake_up_date_time <= this.next_entry_date_noon &&
      this.rise_from_bed_date_time > this.next_entry_date_noon
    ) {
      entry.bedToLightsOffChkFlg = 0;
      entry.lightsOffToSleepChkFlg = 0;
      entry.sleepToWakeChkFlg = 0;
      entry.lastWakeToRiseChkFlg = 0;

      newEntry.lastWakeToRiseChkFlg = 1;
      newEntry.wakeToRiseChkFlg = 2;
      newEntry.last_rise_from_bed_date_time = entry.rise_from_bed_date_time;
    } else if (
      this.go_to_sleep_date_time <= this.next_entry_date_noon &&
      this.wake_up_date_time > this.next_entry_date_noon
    ) {
      entry.bedToLightsOffChkFlg = 0;
      entry.lightsOffToSleepChkFlg = 0;
      entry.lastSleepToWakeChkFlg = 0;

      newEntry.sleepToWakeChkFlg = 2;
      newEntry.lastSleepToWakeChkFlg = 1;
      newEntry.last_wake_up_date_time = entry.wake_up_date_time;
      newEntry.last_rise_from_bed_date_time = entry.rise_from_bed_date_time;
    } else if (
      this.lights_off_date_time <= this.next_entry_date_noon &&
      this.go_to_sleep_date_time > this.next_entry_date_noon
    ) {
      entry.bedToLightsOffChkFlg = 0;
      entry.lastLightsOffToSleepChkFlg = 0;

      newEntry.lightsOffToSleepChkFlg = 2;
      newEntry.lastLightsOffToSleepChkFlg = 1;
      newEntry.last_go_to_sleep_date_time = entry.go_to_sleep_date_time;
      newEntry.last_wake_up_date_time = entry.wake_up_date_time;
      newEntry.last_rise_from_bed_date_time = entry.rise_from_bed_date_time;
    }

    return newEntry;
  }

  haslastSecondSegment(entry: any): boolean {
    if (
      entry.bedToLightsOffChkFlg === 1 ||
      entry.lightsOffToSleepChkFlg === 1 ||
      entry.sleepToWakeChkFlg === 1 ||
      entry.wakeToRiseChkFlg === 1
    ) {
      return true;
    } else {
      return false;
    }
  }

  // convertTimeStringToDate(timeString: string): Date | null {
  //   const [hours, minutes, seconds] = timeString.split(':').map(Number);

  //   if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
  //     return null; // Return null if the time string is invalid
  //   }

  //   // Create a new Date object for today and set the hours, minutes, and AM/PM
  //   const date = new Date();

  //   date.setHours(hours);
  //   date.setMinutes(minutes);
  //   date.setSeconds(0);
  //   date.setMilliseconds(0);

  //   return date;
  // }

  calculateGoToSleepTime(lightsOffTime: string, minutesFallingSleep: number) {
    const lightsoff_time = new Date(lightsOffTime);

    const durationInMs = minutesFallingSleep * 60 * 1000;
    const gotosleeptime = new Date(lightsoff_time.getTime() + durationInMs);

    const year = gotosleeptime.getFullYear();
    const month = String(gotosleeptime.getMonth() + 1).padStart(2, '0');
    const day = String(gotosleeptime.getDate()).padStart(2, '0');

    const date = `${year}-${month}-${day}`;

    let hours = gotosleeptime.getHours();
    const minutes = gotosleeptime.getMinutes();
    const seconds = gotosleeptime.getSeconds();

    hours = hours % 24;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${date}T${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  calculateWidth(startTime: string, endTime: string): number {
    const start = new Date(startTime).getTime();
    const end = new Date(endTime).getTime();
    const durationInMinutes = Math.floor((end - start) / 60000);
    return (durationInMinutes / 1440) * 100;
  }

  parseTimeHM(dateTime: string): { hours: number; minutes: number } {
    if (dateTime && dateTime.includes('T')) {
      const [date, time] = dateTime.split('T');
      const [hours, minutes, seconds] = time.split(':').map(Number);
      return { hours, minutes };
    }
  }

  parseNewTimeHM(time: string): { hours: number; minutes: number } {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return { hours, minutes };
  }

  // Get the minutes elapsed since noon (12 PM), adjusting for AM/PM
  getMinutesSinceNoon(time: { hours: number; minutes: number }): number {
    let totalMinutes = time.hours * 60 + time.minutes;

    if (time.hours >= 12) {
      totalMinutes -= 12 * 60;
    } else {
      totalMinutes += 12 * 60;
    }

    return totalMinutes;
  }

  parseTimeMinutes(dateTime: string): number {
    if (dateTime && dateTime.includes('T') && dateTime.includes(':')) {
      const [date, time] = dateTime.split('T');
      const [hours, minutes, seconds] = time.split(':').map(Number);
      return hours * 60 + minutes;
    } else {
      return 0;
    }
  }

  calculateFirstSegmentWidth(startTime: string): number {
    if (startTime && startTime.includes('T')) {
      const start = new Date(startTime);
      const date = startTime.split('T')[0];
      const startMinutes = this.parseTimeMinutes(startTime);
      const noonMinutes = 12 * 60;
      let endTimeNoon: Date;
      if (startMinutes <= noonMinutes) {
        endTimeNoon = new Date(`${date}T12:00:00`);
      } else {
        endTimeNoon = new Date(`${date}T12:00:00`);
        endTimeNoon.setDate(endTimeNoon.getDate() + 1);
      }
      const durationInMinutes = Math.floor(
        (endTimeNoon.getTime() - start.getTime()) / 60000
      );
      return (durationInMinutes / 1440) * 100;
    }
    return 0;

    // const startMinutes = this.parseTimeMinutes(startTime);
    // const noonMinutes = 12 * 60;
    // if (startMinutes < noonMinutes) {

    //   return ((noonMinutes - startMinutes) / 1440) * 100;
    // }
  }

  calculateSecondSegmentWidth(endTime: string): number {
    if (endTime && endTime.includes('T')) {
      const end = new Date(endTime);
      const date = endTime.split('T')[0];
      const endMinutes = this.parseTimeMinutes(endTime);
      const noonMinutes = 12 * 60;
      let startTimeNoon: Date;
      if (endMinutes >= noonMinutes) {
        startTimeNoon = new Date(`${date}T12:00:00`);
      } else {
        startTimeNoon = new Date(`${date}T12:00:00`);
        startTimeNoon.setDate(startTimeNoon.getDate() - 1);
      }
      const durationInMinutes = Math.floor(
        (end.getTime() - startTimeNoon.getTime()) / 60000
      );
      return (durationInMinutes / 1440) * 100;
    }
    return 0;

    // const endMinutes = this.parseTimeMinutes(endTime);
    // const noonMinutes = 12 * 60;
    // if (endMinutes >= noonMinutes) {
    //   return ((endMinutes - noonMinutes) / 1440) * 100;
    // }
  }

  calculateLeftOffset(time: string): number {
    const parsedTime = this.parseTimeHM(time);
    const minutesSinceNoon = this.getMinutesSinceNoon(parsedTime);
    return (minutesSinceNoon / 1440) * 100;
  }

  calculateLeftNewOffset(time: string): number {
    const parsedTime = this.parseNewTimeHM(time);
    const minutesSinceNoon = this.getMinutesSinceNoon(parsedTime);
    return (minutesSinceNoon / 1440) * 100;
  }

  calculatePreviousFirstSegment(entry): any {
    if (entry.previousBedToLightsOffChkFlg === 1) {
      const goToBedTime = this.parseTimeMinutes(
        entry.previous_go_to_bed_date_time
      );
      if (goToBedTime < 12 * 60) {
        const firstSegmentWidth = this.calculateFirstSegmentWidth(
          entry.previous_go_to_bed_date_time
        );

        return {
          left:
            this.calculateLeftOffset(entry.previous_go_to_bed_date_time) + '%',
          width: firstSegmentWidth + '%',
          color: 'red',
        };
      }
    }

    if (entry.previousLightsOffToSleepChkFlg === 1) {
      const lightsOffTime = this.parseTimeMinutes(
        entry.previous_lights_off_date_time
      );
      if (lightsOffTime < 12 * 60) {
        const firstSegmentWidth = this.calculateFirstSegmentWidth(
          entry.previous_lights_off_date_time
        );

        return {
          left:
            this.calculateLeftOffset(entry.previous_lights_off_date_time) + '%',
          width: firstSegmentWidth + '%',
          color: 'purple',
        };
      }
    }

    if (entry.previousSleepToWakeChkFlg === 1) {
      const goToSleepTime = this.parseTimeMinutes(entry.go_to_sleep_date_time);
      // if (goToSleepTime < 12 * 60) {
      const firstSegmentWidth = this.calculateFirstSegmentWidth(
        entry.previous_go_to_sleep_date_time
      );

      return {
        left:
          this.calculateLeftOffset(entry.previous_go_to_sleep_date_time) + '%',
        width: firstSegmentWidth + '%',
        color: 'blue',
      };
    }

    if (entry.previousWakeToRiseChkFlg === 1) {
      const wakeUpTime = this.parseTimeMinutes(
        entry.previous_wake_up_date_time
      );
      if (wakeUpTime < 12 * 60) {
        const firstSegmentWidth = this.calculateFirstSegmentWidth(
          entry.previous_wake_up_date_time
        );
        return {
          left:
            this.calculateLeftOffset(entry.previous_wake_up_date_time) + '%',
          width: firstSegmentWidth + '%',
          color: 'green',
        };
      }
    }

    return null;
  }

  calculateFirstSegment(entry): any {
    if (entry.lastBedToLightsOffChkFlg === 0) {
      const goToBedTime = this.parseTimeMinutes(entry.go_to_bed_date_time);
      if (goToBedTime < 12 * 60) {
        const firstSegmentWidth = this.calculateFirstSegmentWidth(
          entry.go_to_bed_date_time
        );
        return {
          left: this.calculateLeftOffset(entry.go_to_bed_date_time) + '%',
          width: firstSegmentWidth + '%',
          color: 'red',
        };
      }
    }

    if (entry.lastLightsOffToSleepChkFlg === 0) {
      const lightsOffTime = this.parseTimeMinutes(entry.lights_off_date_time);
      if (lightsOffTime < 12 * 60) {
        const firstSegmentWidth = this.calculateFirstSegmentWidth(
          entry.lights_off_date_time
        );
        return {
          left: this.calculateLeftOffset(entry.lights_off_date_time) + '%',
          width: firstSegmentWidth + '%',
          color: 'purple',
        };
      }
    }

    if (entry.lastSleepToWakeChkFlg === 0) {
      const goToSleepTime = this.parseTimeMinutes(entry.go_to_sleep_date_time);
      // if (goToSleepTime < 12 * 60) {
      const firstSegmentWidth = this.calculateFirstSegmentWidth(
        entry.go_to_sleep_date_time
      );
      return {
        left: this.calculateLeftOffset(entry.go_to_sleep_date_time) + '%',
        width: firstSegmentWidth + '%',
        color: 'blue',
      };
    }
    //   else {
    //     const startMinutes = this.parseTimeMinutes(entry.go_to_sleep_date_time);
    //     const halfDayMinutes = 12 * 60;
    //     const fullDayMinutes = 24 * 60;
    //     if (startMinutes > halfDayMinutes) {
    //       return {
    //         left: this.calculateLeftOffset(entry.go_to_sleep_date_time) + '%',
    //         width:
    //           ((fullDayMinutes - startMinutes + halfDayMinutes) / 1440) * 100 +
    //           '%',
    //         color: 'blue',
    //       };
    //     }
    //   }
    // }

    if (entry.lastWakeToRiseChkFlg === 0) {
      const wakeUpTime = this.parseTimeMinutes(entry.wake_up_date_time);
      if (wakeUpTime < 12 * 60) {
        const firstSegmentWidth = this.calculateFirstSegmentWidth(
          entry.wake_up_date_time
        );
        return {
          left: this.calculateLeftOffset(entry.wake_up_date_time) + '%',
          width: firstSegmentWidth + '%',
          color: 'green',
        };
      }
    }

    return null;
  }

  calculateSecondSegment(entry): any {
    const fullDayMinutes = 24 * 60; // Full day in minutes (1440)
    const noonMinutes = 12 * 60; // 12 PM in minutes (720)
    const nextDayStartOffset = fullDayMinutes + noonMinutes;

    if (entry.previousBedToLightsOffChkFlg === 0) {
      const lightsOffTime: number = this.parseTimeMinutes(
        entry.lights_off_date_time
      );
      if (lightsOffTime >= noonMinutes) {
        const secondSegmentWidth = this.calculateSecondSegmentWidth(
          entry.lights_off_date_time
        );

        // For the regular case where time does not cross to the next day
        return {
          left: this.calculateLeftNewOffset('12:00 PM') + '%',
          width: secondSegmentWidth + '%',
          color: 'red',
        };
      }
    }

    if (entry.previousLightsOffToSleepChkFlg === 0) {
      const goToSleepTime: number = this.parseTimeMinutes(
        entry.go_to_sleep_date_time
      );
      if (goToSleepTime >= noonMinutes) {
        const secondSegmentWidth = this.calculateSecondSegmentWidth(
          entry.go_to_sleep_date_time
        );

        return {
          left: this.calculateLeftNewOffset('12:00 PM') + '%',
          width: secondSegmentWidth + '%',
          color: 'purple',
        };
      }
    }

    if (entry.previousSleepToWakeChkFlg === 0) {
      const wakeUpTime = this.parseTimeMinutes(entry.wake_up_date_time);
      // if (wakeUpTime >= noonMinutes) {
      const secondSegmentWidth = this.calculateSecondSegmentWidth(
        entry.wake_up_date_time
      );

      return {
        left: this.calculateLeftNewOffset('12:00 PM') + '%',
        // left: '100%',
        width: secondSegmentWidth + '%',
        color: 'blue',
      };
    }
    // else {
    //   const endMinutes = this.parseTimeMinutes(entry.wake_up_date_time);
    //   const halfDayMinutes = 12 * 60;
    //   if (endMinutes < halfDayMinutes) {
    //     return {
    //       left: this.calculateLeftNewOffset('12:00 PM') + '%',

    //       width: ((endMinutes + halfDayMinutes) / 1440) * 100 + '%',
    //       color: 'blue',
    //     };
    //   }
    //   }
    // }

    if (entry.previousWakeToRiseChkFlg === 0) {
      const riseFromBed = this.parseTimeMinutes(entry.rise_from_bed_date_time);
      if (riseFromBed >= noonMinutes) {
        const secondSegmentWidth = this.calculateSecondSegmentWidth(
          entry.rise_from_bed_date_time
        );

        return {
          left: this.calculateLeftNewOffset('12:00 PM') + '%',
          width: secondSegmentWidth + '%',
          color: 'green',
        };
      }
    }

    return null;
  }

  calculateNextSecondSegment(entry): any {
    const fullDayMinutes = 24 * 60; // Full day in minutes (1440)
    const noonMinutes = 12 * 60; // 12 PM in minutes (720)

    // if (entry.lastBedToLightsOffChkFlg === 1) {
    //   const lightsOffTime: number = this.parseTimeMinutes(
    //     entry.last_lights_off_date_time
    //   );
    //   if (lightsOffTime >= noonMinutes) {
    //     const secondSegmentWidth = this.calculateSecondSegmentWidth(
    //       entry.last_lights_off_date_time
    //     );

    //     // For the regular case where time does not cross to the next day
    //     return {
    //       left: this.calculateLeftNewOffset('12:00 PM') + '%',
    //       width: secondSegmentWidth + '%',
    //       color: 'red',
    //     };
    //   }
    // }

    if (entry.lastLightsOffToSleepChkFlg === 1) {
      const goToSleepTime: number = this.parseTimeMinutes(
        entry.last_go_to_sleep_date_time
      );
      if (goToSleepTime >= noonMinutes) {
        const secondSegmentWidth = this.calculateSecondSegmentWidth(
          entry.last_go_to_sleep_date_time
        );

        return {
          left: this.calculateLeftNewOffset('12:00 PM') + '%',
          width: secondSegmentWidth + '%',
          color: 'purple',
        };
      }
    }

    if (entry.lastSleepToWakeChkFlg === 1) {
      const wakeUpTime = this.parseTimeMinutes(entry.last_wake_up_date_time);
      // if (wakeUpTime >= noonMinutes) {
      const secondSegmentWidth = this.calculateSecondSegmentWidth(
        entry.last_wake_up_date_time
      );

      return {
        left: this.calculateLeftNewOffset('12:00 PM') + '%',
        width: secondSegmentWidth + '%',
        color: 'blue',
      };
    }
    //   else {
    //     const endMinutes = this.parseTimeMinutes(entry.last_wake_up_date_time);
    //     const halfDayMinutes = 12 * 60;
    //     if (endMinutes < halfDayMinutes) {
    //       return {
    //         left: this.calculateLeftNewOffset('12:00 PM') + '%',

    //         width: ((endMinutes + halfDayMinutes) / 1440) * 100 + '%',
    //         color: 'blue',
    //       };
    //     }
    //   }
    // }

    if (entry.lastWakeToRiseChkFlg === 1) {
      const riseFromBed = this.parseTimeMinutes(
        entry.last_rise_from_bed_date_time
      );

      if (riseFromBed >= noonMinutes) {
        const secondSegmentWidth = this.calculateSecondSegmentWidth(
          entry.last_rise_from_bed_date_time
        );

        return {
          left: this.calculateLeftNewOffset('12:00 PM') + '%',
          width: secondSegmentWidth + '%',
          color: 'green',
        };
      }
      // const secondSegmentWidth = (entry.secondSegmentMinute / 1440) * 100;

      // return {
      //   left: this.calculateLeftOffset('12:00 PM') + '%',
      //   width: secondSegmentWidth + '%',
      //   color: 'green',
      // };
    }
  }

  // // Helper function to parse time strings (hh:MM:ss) into seconds
  // parseTimeHMS(timeStr: string): number {
  //   const [hours, minutes, seconds] = timeStr.split(':').map(Number);
  //   return hours * 3600 + minutes * 60 + seconds;
  // }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  // Method to calculate results based on user input
  calculateSleepEfficiency(): void {
    // Example: You can implement your own logic for calculation
    const totalMinutesInBed = this.timeDifference(
      this.goToBedTime,
      this.riseFromBedTime
    );
    const totalSleepMinutes =
      totalMinutesInBed -
      parseInt(this.goToSleepTime) -
      parseInt(this.minutesAwake);

    this.totalTimeInBed = this.formatMinutes(totalMinutesInBed);
    this.totalSleepTime = this.formatMinutes(totalSleepMinutes);
    this.sleepEfficiency =
      ((totalSleepMinutes / totalMinutesInBed) * 100).toFixed(2) + '%';
  }

  // Utility function to calculate time difference in minutes
  timeDifference(startTime: string, endTime: string): number {
    const start = this.parseTime(startTime);
    const end = this.parseTime(endTime);
    return (end - start + 1440) % 1440; // Adjust for overnight times
  }

  // Utility function to parse time in "HH:mm" format to minutes since midnight
  parseTime(dateTime: string): number {
    if (dateTime.includes('T') && dateTime.includes(':')) {
      const [date, time] = dateTime.split('T');
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    } else {
      return 0;
    }
  }

  // Format minutes to "HH:mm" format
  formatMinutes(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${this.padZero(hours)}:${this.padZero(mins)}`;
  }

  // Pad single digit number with leading zero
  padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  // Event handler for Next button
  onSubmit(): void {
    this.calculateSleepEfficiency();

    // Parameter (2): The index starts from 0.
    this.courseService.unlockWeek(2);

    //  Parameter (2): The index starts from 0, unlock the third page of the current week
    this.courseService.unlockWeek3Content(2);

    //  Parameter (3): Indicates the number of the current week.
    //  Parameter (3): Indicates the third page of the current week.
    this.courseService.updateProgress(3, 3);

    this.router.navigate(['week3/what_controls_sleep']);
  }
}
