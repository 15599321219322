import { Component, OnInit } from '@angular/core';
import { Contactus } from '../_models/contactus';
import {
  FormsModule,
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';
import { AccountService, AlertService } from '@app/_services';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.less'],
})
export class ContactusComponent implements OnInit {
  // model = new Contactus();
  submitted = false;
  contactUsForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private accountService: AccountService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.intializeForm();
  }

  intializeForm(): void {
    this.contactUsForm = this.fb.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [
        Validators.required,
        Validators.email,

        // Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
      ]),
      phoneNumber: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.maxLength(365)]),
    });
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.contactUsForm.invalid) {
      return;
    }
    // this.loading = true;
    this.accountService
      .contact(this.contactUsForm.value)
      .pipe(first())
      .subscribe({
        next: () => {
          // get return url from query parameters or default to home page
          this.alertService.info(
            'Thank You! You entry has been saved, we will contact you shortly'
          );
          // const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
          this.contactUsForm.reset();
          this.submitted = false;
          // this.router.navigate(['/contact']);
        },
        error: (error) => {
          this.alertService.error(error);

          // this.loading = false;
        },
      });
  }

  // get reference(): FormArray{

  // }
}
